import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 4rem;
  margin-top: 12rem;

  .swiper-slide {
    width: 36rem;
    height: 20rem;
    border-radius: 1rem;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 930px) {
    margin-top: 4rem;
    .swiper-slide {
      width: 100%;
      max-width: 20rem;
      height: auto;
      min-height: 10rem;
    }
  }

  @media (max-width: 500px) {
    margin-top: 2rem;
  }

  @media (max-width: 440px) {
    padding-left: 2rem;
  }

  @media (max-width: 400px) {
    padding-left: 0;
    padding: 0 2rem;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 17rem;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;

    @media (max-width: 500px) {
      width: 80%;
    }
  }

  @media (max-width: 930px) {
    max-width: 10rem;
  }
`;
