import { IAutoSelectProps } from '~/models/Common';

export default function formatToOptions(
  options: any[],
  value: string,
  label: string,
): IAutoSelectProps[] {
  return options.map((option) => ({
    value: option[value],
    label: option[label],
    id: option[value],
  }));
}
