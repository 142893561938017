import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  /* max-width: 294px; */
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  border: 1px solid #c1c1c1;
  margin-bottom: 20px;
  background: #f3f3f366 0% 0% no-repeat padding-box;
  padding-right: 2rem;

  // Icon:
  > svg {
    color: #c1c1c1;
    transition: 300ms ease all;
  }

  > input {
    flex: 1;
    width: 100%;
    background: #f9f9f9;
    border: 0;
    border-radius: 0.8rem;
    padding: 5px 20px;

    color: #394761;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.52px;

    ::placeholder {
      color: #39476180;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: 0.52px;
    }
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030 !important;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border: 1px solid #dd5c32;
      svg {
        transform: rotate(-180deg);
        color: #dd5c32;
      }
    `}

  /* Se o input estiver preenchido, mude a cor do icone. A cor do icone esta conectado com color */
  ${(props) =>
    props.isFilled &&
    css`
      border: 1px solid #dd5c32;
    `}
`;

export const Error = styled(Tooltip)`
  height: 2rem;
  margin-right: 0.5rem;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
