import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSProperties } from 'styled-components';

import { Button, StarRating } from '~/components';
import { ILead } from '~/models/Lead';
import { AdminService } from '~/services';
import {
  handleFormatDate,
  handleFormatHourFromDate,
  handleFormatPhoneNumber,
} from '~/utils/functions';

import * as S from './styles';

interface ILeadDetailParams {
  id: string;
}

function LeadDetail(): ReactElement {
  const [lead, setLead] = useState<ILead>();
  const router = useHistory();
  const { id } = useParams<ILeadDetailParams>();

  const goBackButtonStyle = useRef<CSSProperties>({
    borderRadius: '10px',
    maxWidth: '214px',
  });

  const lastColumnStyle = useRef<CSSProperties>({ paddingTop: '5.6rem' });

  const handleGoBack = useCallback(() => {
    router.goBack();
  }, [router]);

  const handleGetLeadById = useCallback(async () => {
    try {
      const response = await AdminService.getLeadById(id);
      setLead(response.data);
    } catch (error) {
      toast.error('Erro ao carregar lead');
      handleGoBack();
    }
  }, [handleGoBack, id]);

  const DateCountryOffset = (currentDate: string): string => {
    const dateOffet = new Date(currentDate);
    dateOffet.setHours(
      dateOffet.getHours() - dateOffet.getTimezoneOffset() / 60,
    );
    return dateOffet.toISOString();
  };

  useEffect(() => {
    handleGetLeadById();
  }, [handleGetLeadById]);

  return (
    <S.Container>
      <S.LeadInfo>
        <S.LeadInfoImgBox>
          <S.LeadInfoImg
            src={lead?.user.image_url ? lead?.user.image_url : ''}
            alt={lead?.user.name}
            title={lead?.user.name}
          />
        </S.LeadInfoImgBox>
        <S.LeadInfoData>
          <S.LeadInfoDataHeader>
            <S.LeadInfoDataList>
              <S.LeadInfoDataItem>
                <S.LeadInfoDataTitle>Cliente: </S.LeadInfoDataTitle>
                <S.LeadInfoDataValue>{lead?.user.name}</S.LeadInfoDataValue>
              </S.LeadInfoDataItem>
            </S.LeadInfoDataList>
          </S.LeadInfoDataHeader>
          <S.LeadInfoDataTitle as="h1" style={{ textAlign: 'center' }}>
            Dados da Solicitação
          </S.LeadInfoDataTitle>
        </S.LeadInfoData>
      </S.LeadInfo>
      <S.LeadDetailWrapper>
        <S.LeadDetailDataWrapper>
          <S.LeadDetailDataCol>
            <S.LeadDetailDataColTitle>
              Dados do Cliente
            </S.LeadDetailDataColTitle>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>Nome</S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.user.name ?? '-'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>

            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>Telefone</S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.user && lead?.user.phone
                  ? handleFormatPhoneNumber(lead.user.phone)
                  : '-'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>E-mail</S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.user?.email ?? '-'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>CNPJ</S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.user.cnpj
                  ?.toString()
                  .replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4-$5',
                  ) ?? '-'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>Região</S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.address.neighborhood} {lead?.address.city}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
          </S.LeadDetailDataCol>
          <S.LeadDetailDataCol>
            <S.LeadDetailDataColTitle>
              Dados da Solicitação
            </S.LeadDetailDataColTitle>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>Status</S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.status === 'Pending'
                  ? 'Aguardando atendimento'
                  : 'Atendido'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>Operadoras</S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.operators && lead?.operators.length > 0
                  ? lead?.operators?.map(
                      (operator, index) =>
                        `${operator.name} ${
                          lead?.operators.length - 1 !== index ? ' | ' : ''
                        }`,
                    )
                  : '-'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>
                Serviço solicitado
              </S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.services && lead?.services.length > 0
                  ? lead?.services?.map(
                      (service, index) =>
                        `${service.name} ${
                          lead?.services.length - 1 !== index ? '| ' : ''
                        }`,
                    )
                  : '-'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>
                Valor do atendimento
              </S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.lead_type ? `R$ ${lead?.lead_type.credit_price}` : '-'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
          </S.LeadDetailDataCol>
          <S.LeadDetailDataCol style={lastColumnStyle.current}>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>
                Horário{' '}
                {lead?.status === 'Pending' ? 'agendado' : 'do atendimento'}
              </S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.first_scheduling_date || lead?.second_scheduling_date ? (
                  <>
                    {handleFormatDate(lead?.updated_at)} -{' '}
                    {handleFormatHourFromDate(
                      lead?.status === 'Pending'
                        ? lead?.first_scheduling_date
                        : DateCountryOffset(lead?.updated_at),
                    )}
                    {lead?.status === 'Pending' &&
                      ` | ${handleFormatDate(
                        lead?.second_scheduling_date,
                      )} - ${handleFormatHourFromDate(
                        lead?.second_scheduling_date,
                      )}`}
                  </>
                ) : (
                  '-'
                )}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>
                Tipo de atendimento
              </S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.lead_type?.name ?? '-'}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>
                Forma de atendimento
              </S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.contact_by_whatsapp !== undefined ? (
                  <>{lead?.contact_by_whatsapp ? 'Whatsapp' : 'Telefone'}</>
                ) : (
                  '-'
                )}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>
                Data e horário do pedido
              </S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.created_at ? (
                  <>
                    {handleFormatDate(lead?.created_at)} -{' '}
                    {handleFormatHourFromDate(
                      DateCountryOffset(lead?.created_at),
                    )}
                  </>
                ) : (
                  '-'
                )}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
            <S.LeadDetailDataItem>
              <S.LeadDetailDataItemKey>Avaliação</S.LeadDetailDataItemKey>
              <S.LeadDetailDataItemValue>
                {lead?.rating ? (
                  <StarRating
                    name="readonly"
                    value={parseInt(lead?.rating, 10)}
                    precision={0.5}
                    readOnly
                  />
                ) : (
                  'Não avaliado'
                )}
              </S.LeadDetailDataItemValue>
            </S.LeadDetailDataItem>
          </S.LeadDetailDataCol>
        </S.LeadDetailDataWrapper>

        <S.LeadDetailFooter>
          <S.ButtonGroup>
            <Button
              onClick={handleGoBack}
              containerStyle={goBackButtonStyle.current}
            >
              Voltar
            </Button>
            <a
              href={`mailto:${lead?.user.email}`}
              style={{ maxWidth: '323px', width: '100%' }}
            >
              <Button containerStyle={goBackButtonStyle.current}>
                Enviar email
              </Button>
            </a>
          </S.ButtonGroup>
        </S.LeadDetailFooter>
      </S.LeadDetailWrapper>
    </S.Container>
  );
}

export default LeadDetail;
