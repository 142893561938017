export default {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    primary: '#FFB70F',
    secondary: '#222222',
    gray: '#F8F8F8',
    green: '#00CC00',
    red: '#BB0000',
  },
  spacings: {
    small: '1.5rem',
    medium: '3rem',
    large: '5rem',
    xl: '10rem',
  },
  font: {
    sizes: {
      small: '1.5rem',
      medium: '1.8rem',
      large: '3rem',
      superLarge: '5rem',
      xl: '10rem',
    },
  },
};
