import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import Tooltip from '../Tooltip';

interface InputProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const AutoSelectInput = styled(CreatableSelect)<InputProps>`
  width: 100%;

  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    height: 100%;
    min-height: 5rem;
    border-radius: 0.8rem;
    border: 1px solid #c1c1c1;
    outline: none !important;
    box-shadow: none;

    background: #f3f3f366 0% 0% no-repeat padding-box;
    color: #394761;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.52px;

    ::placeholder {
      color: #39476180;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: 0.52px;
    }

    ${(props) =>
      props.isErrored &&
      css`
        border-color: #c53030 !important;
      `}

    ${(props) =>
      props.isFilled &&
      css`
        border: 1px solid #dd5c32 !important;
      `}
  }

  .css-26l3qy-menu {
    position: relative;
    z-index: 3;
    div div {
      height: 4rem;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 2rem;
  margin-left: 0.5rem;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
