import { ReactElement } from 'react';

// import {
//   VivoLogo,
//   TimLogo,
//   ClaroLogo,
//   OiLogo,
//   VeroLogo,
//   UmtelecomLogo,
//   TelecallLogo,
//   Net2phoneLogo,
//   MobLogo,
//   SurfixLogo,
// } from '~/assets/images';
import {
  AmilLogo,
  HumanaLogo,
  QualiLogo,
  SampLogo,
  UnimedLogo,
  UnimedLogoLondrina,
  UnimedLogoMaringa,
} from '~/assets/images/plans';
import { Footer, Carousel } from '~/components';

import HomeTitle from './HomeTitle';
import HomeDescription from './HomeDescription';
import Forms from '~/pages/Forms';

import * as S from './styles';

function Home(): ReactElement {
  const companiesImages = [
    AmilLogo,
    UnimedLogo,
    HumanaLogo,
    UnimedLogoLondrina,
    QualiLogo,
    SampLogo,
    UnimedLogoMaringa,
  ];

  return (
    <S.Container>
      <HomeTitle />
      <Carousel images={companiesImages} />
      <HomeDescription />
      <Forms />
      <Footer />
    </S.Container>
  );
}

export default Home;
