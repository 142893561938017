import React, { useMemo, useState } from 'react';

import SwiperCore, { Lazy, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';
import { Container, ImageContainer } from './styles';

SwiperCore.use([Lazy, Autoplay]);

interface CarouselProps {
  images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [imagesComponent, setImagesComponent] = useState<JSX.Element[]>();

  useMemo(() => {
    const temp = images.map((image, index) => (
      <SwiperSlide
        // eslint-disable-next-line react/no-array-index-key
        key={`slide-${index}`}
        tag="div"
      >
        <ImageContainer>
          <img src={image} alt="LogoOperadora" />
        </ImageContainer>
      </SwiperSlide>
    ));
    setImagesComponent(temp);
  }, [images]);

  return (
    <Container>
      <Swiper
        id="main"
        spaceBetween={40}
        slidesPerView="auto"
        loop
        lazy
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
      >
        {imagesComponent}
      </Swiper>
    </Container>
  );
};

export default Carousel;
