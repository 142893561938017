/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  SelectHTMLAttributes,
} from 'react';

import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { Container, AutoSelectInput, Error } from './styles';
import { IAutoSelectProps } from '~/models/Common';

interface AutoSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: IAutoSelectProps[];
  setOptions: (state: IAutoSelectProps[]) => void;
  newOptions: string[];
  setNewOptions: (state: string[]) => void;
  name: string;
  placeholder?: string;
  onChangeCallback?: () => void;
}

const AutoSelectCreatable: React.FC<AutoSelectProps> = ({
  options,
  setOptions,
  newOptions,
  setNewOptions,
  name,
  placeholder = 'Selecione uma opção',
  onChangeCallback,
}) => {
  const inputRef = useRef<HTMLSelectElement>(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [isFocused, setIsFocused] = useState(false); // Se esta com foco
  const [isFilled, setIsFilled] = useState(false); // Se esta preenchido
  const [value, setValue] = useState<IAutoSelectProps[]>([]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    // Verifica se o inputRef tem um valor/value. Se tiver preenchido = true. Se tiver vazio = false. !! Tranforma o value em booleano.
    setIsFilled(!!inputRef.current?.value); // inputRef pega o valor direto do Input. document.querySelector('input') e etc.
  }, []);

  const handleChange = useCallback(
    (e) => {
      setValue(e);
      if (inputRef.current) {
        inputRef.current.value = e;
      }
      onChangeCallback && onChangeCallback();
    },
    [setValue, onChangeCallback],
  );

  const handleCreateOption = useCallback(
    (option: string) => {
      setOptions([...options, { id: 'default', value: option, label: option }]);
      setNewOptions([...newOptions, option]);
      if (inputRef.current) {
        handleChange([
          ...value,
          {
            id: 'default',
            value: option,
            label: option,
          },
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange, setOptions, setNewOptions, options, newOptions],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <AutoSelectInput
        isMulti
        options={options}
        defaultValue={defaultValue}
        name={name}
        ref={inputRef}
        placeholder={placeholder}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        onFocus={handleInputFocus} // Receber o foco
        onBlur={handleInputBlur} // Perder o foco
        onChange={(e: unknown) => handleChange(e)}
        formatCreateLabel={(userInput: string) =>
          `Solicitar nova operadora: "${userInput}"`
        }
        value={value}
        onCreateOption={(option: string) => {
          handleCreateOption(option);
        }}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default AutoSelectCreatable;
