import { ReactElement, useCallback } from 'react';

import { AxiosPromise } from 'axios';
import { toast } from 'react-toastify';

import { Table } from '~/components';
import { AdminService } from '~/services';
import { handleDownloadReport } from '~/utils/functions';

import * as S from './styles';

function Reports(): ReactElement {
  const handleGetReport = useCallback(
    async (method: () => AxiosPromise, reportTitle: string) => {
      try {
        await handleDownloadReport(method, reportTitle);
      } catch (error) {
        toast.error(`Não foi possível baixar o relatório`);
      }
    },
    [],
  );

  return (
    <>
      <S.Container>
        <S.Title>Relatórios</S.Title>
        <S.TableWrapper>
          <Table
            tableHeadChildren={() => (
              <>
                <th>Tipo de relatório</th>
                <th> </th>
              </>
            )}
          >
            <tr
              onClick={() =>
                handleGetReport(
                  AdminService.getCustomersReport,
                  'relatório_de_clientes',
                )
              }
            >
              <td>Clientes</td>
              <td style={S.DownloadTD}>Download</td>
            </tr>
            <tr
              onClick={() =>
                handleGetReport(
                  AdminService.getSellersReport,
                  'relatório_de_vendedores',
                )
              }
            >
              <td>Vendedores</td>
              <td style={S.DownloadTD}>Download</td>
            </tr>
          </Table>
        </S.TableWrapper>
      </S.Container>
    </>
  );
}

export default Reports;
