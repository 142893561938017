import { AxiosPromise } from 'axios';

import api from './api';

import { ISeller } from '~/models/Seller';
import {
  ILeadsPageOptionsRequest,
  IOperatorsPageOptionsRequest,
  IPagination,
  ISellerPageOptionsRequest,
  ISuggestOperatorsPageOptionsRequest,
} from '~/models/Common';
import { PageOptionsRequest } from '~/components/Pagination';
import { CreateOperatorDto } from '~/utils/dtos/create-operator';
import { ILead } from '~/models/Lead';
import { IOperator, ISuggestedOperator } from '~/models/Operator';
import { UpdateSellerCreditsDto } from '~/utils/dtos/update-seller-credits';
import { UpdateOperatorStatusDto } from '~/utils/dtos/update-operator-status';
import { UpdateOperatorDto } from '~/utils/dtos/update-operator';
import { IMensageForm } from '~/pages/Admin/Push';

export default class AdminService {
  static baseRoute = '/admin';

  static PER_PAGE_DEFAULT = 8;

  static getAllLeads(
    {
      page,
      perpage = this.PER_PAGE_DEFAULT,
      name,
      status,
      sortBy,
      direction,
    }: ILeadsPageOptionsRequest = {
      page: 1,
      perpage: this.PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<IPagination<ILead[]>> {
    return api.get(`${this.baseRoute}/leads`, {
      params: {
        page,
        perpage,
        name,
        status,
        sortBy,
        direction,
      },
    });
  }

  static getLeadById(leadId: string): AxiosPromise<ILead> {
    return api.get(`/leads/${leadId}`);
  }

  static getSeller(sellerId: string): AxiosPromise<ISeller> {
    return api.get(`${this.baseRoute}/sellers/${sellerId}`);
  }

  static getAllSellers(
    {
      page,
      perpage = this.PER_PAGE_DEFAULT,
      sortBy,
      active,
      direction,
    }: ISellerPageOptionsRequest = {
      page: 1,
      perpage: this.PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<IPagination<ISeller[]>> {
    return api.get(`${this.baseRoute}/sellers`, {
      params: {
        page,
        perpage,
        sortBy,
        active,
        direction,
      },
    });
  }

  static putAcceptRecuseSeller(
    sellerId: string,
    active: boolean,
  ): AxiosPromise {
    return api.put(`${this.baseRoute}/sellers/accept-recuse/${sellerId}`, {
      active,
    });
  }

  static putUpdateSellerCredits(
    sellerId: string,
    data: UpdateSellerCreditsDto,
  ): AxiosPromise {
    return api.put(`${this.baseRoute}/sellers/${sellerId}/credits`, data);
  }

  static PushMensage(data: IMensageForm): AxiosPromise {
    return api.post(`${this.baseRoute}/notify/sellers`, data);
  }

  static postCreateOperator(data: CreateOperatorDto): AxiosPromise {
    return api.post(`${this.baseRoute}/operators/create`, data);
  }

  static putUpdateOperator(
    data: UpdateOperatorDto,
    operatorId: string,
  ): AxiosPromise {
    return api.put(`${this.baseRoute}/operators/${operatorId}/update`, data);
  }

  static putUpdateOperatorStatus(
    data: UpdateOperatorStatusDto,
    operatorId: string,
  ): AxiosPromise {
    return api.put(`${this.baseRoute}/operators/${operatorId}/status`, data);
  }

  static putUpdateOperatorImage(
    operatorId: string,
    data: FormData,
  ): AxiosPromise {
    return api.put(`${this.baseRoute}/operators/${operatorId}/image`, data);
  }

  static getOperators(
    {
      page,
      perpage = this.PER_PAGE_DEFAULT,
      sortBy,
      direction,
    }: IOperatorsPageOptionsRequest = {
      page: 1,
      perpage: this.PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<IPagination<IOperator[]>> {
    return api.get(`${this.baseRoute}/operators`, {
      params: {
        page,
        perpage,
        sortBy,
        direction,
      },
    });
  }

  static getOperator(operatorId: string): AxiosPromise<IOperator> {
    return api.get(`${this.baseRoute}/operators/${operatorId}`);
  }

  static getOperatorSellers(
    operatorId: string,
    { page, perpage = this.PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: this.PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<IPagination<ISeller[]>> {
    return api.get(`${this.baseRoute}/operators/${operatorId}/sellers`, {
      params: {
        page,
        perPage: perpage,
      },
    });
  }

  static getSuggestOperators(
    {
      page,
      perpage = this.PER_PAGE_DEFAULT,
      sortBy,
    }: ISuggestOperatorsPageOptionsRequest = {
      page: 1,
      perpage: this.PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<IPagination<ISuggestedOperator[]>> {
    return api.get(`${this.baseRoute}/suggest-operators`, {
      params: {
        page,
        perpage,
        sortBy,
      },
    });
  }

  static postAcceptSuggestOperator(suggestOperatorId: string): AxiosPromise {
    return api.get(
      `${this.baseRoute}/suggest-operators/${suggestOperatorId}/accept`,
    );
  }

  static getCustomersReport(): AxiosPromise {
    return api({
      url: `/admin/report-customer`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  static getLeadsReport(): AxiosPromise {
    return api({
      url: `/admin/report-leads`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  static getSellersReport(): AxiosPromise {
    return api({
      url: `/admin/report-seller`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  static getSellersCreditReport(): AxiosPromise {
    return api({
      url: `/admin/report-seller-credits`,
      method: 'GET',
      responseType: 'blob',
    });
  }
}
