import React from 'react';
import { ModalInfo } from '~/components';

import * as S from './styles';

interface ModalTermsProps {
  modalTerms: boolean;
  setModalTerms: (state: boolean) => void;
}

const ModalTerms: React.FC<ModalTermsProps> = ({
  modalTerms,
  setModalTerms,
}) => (
  <ModalInfo
    modalVisible={modalTerms}
    setModalVisible={setModalTerms}
    scrollView
    btnClose
  >
    <S.TermsDiv>
      <h1>TERMOS DE USO</h1>
      <h2>
        AO ACEITAR ESTES TERMOS DE SERVIÇO, VOCÊ CONCORDA EM CUMPRIR ESTA
        CLÁUSULA DE ARBITRAGEM. LEIA ATENTA E CUIDADOSAMENTE
      </h2>
      <p>
        LEIA ATENTA E CUIDADOSAMENTE ESTES TERMOS DE SERVIÇO POIS ELES CONTÊM
        INFORMAÇÕES IMPORTANTES SOBRE SEUS DIREITOS, RECURSOS E OBRIGAÇÕES.
        ESTES INCLUEM CONFORMIDADE COM AS LEIS E REGULAMENTOS APLICÁVEIS.
      </p>
      <p>
        <strong>Termos principais:</strong> A CLIENTCOM declara não possuir
        nenhum tipo de vinculo empregatício ou outro qualquer com os vendedores
        cadastrados ou empresas , realizando apenas a intermediação entre
        usuários, vendedores e empresas, não existindo nenhuma obrigação ou
        subsidiariedade entre as partes. Toda e qualquer informação fornecida
        pelos vendedores, usuários e empresas são de responsabilidade deles, não
        tendo a plataforma nenhuma forma de garantia de veracidade com os
        presentes dados e informações.
      </p>
      <p>
        <strong>TERMOS DE USO:</strong>
      </p>
      <p>
        Ao utilizar o aplicativo, você concorda em cumprir e estar legalmente
        sujeito aos termos e condições destes Termos de Serviço, ainda que você
        não se torne um usuário registrado dos Serviços. Os presentes Termos
        regem o acesso e a utilização do aplicativo, e constituem um contrato
        vinculativo de valor legal entre você e a CLIENTCOM.
      </p>
      <p>
        Por favor ler cuidadosamente a nossa Política de Privacidade. Se você
        não concorda com estes termos, você não tem direito de obter informações
        ou outras formas de continuar a utilizar a plataforma. A não utilização
        da plataforma, de acordo com estes Termos pode sujeitá-lo a penalidades
        civis e criminais.
      </p>
      <p>
        A CLIENTCOM É UM APLICATIVO QUE FORNECE AOS USUÁRIOS A POSSIBILIDADE DE
        SE CONECTAR A VENDEDORES DE SERVIÇOS TELECOM DE DIFERENTES OPERADORAS. O
        APLICATIVO CONTA COM O APOIO DE VENDEDORES CAPACITADOS E CADASTRADOS NA
        PLATAFORMA QUE VAI AUXILIAR E DAR ATENDIMENTO AOS USUÁRIOS.
      </p>
      <p>
        VOCÊ RECONHECE E CONCORDA QUE, AO ACESSAR OU UTILIZAR O APLICATIVO OU
        BAIXANDO, VOCÊ ESTÁ INDICANDO QUE LEU, E QUE COMPREENDE E CONCORDA EM
        CUMPRIR COM ESTES TERMOS E RECEBER NOSSOS SERVIÇOS. SE VOCÊ NÃO CONCORDA
        COM ESTES TERMOS, ENTÃO NÃO TEM O DIREITO DE ACESSAR OU UTILIZAR ESTE
        APLICATIVO, OU O CONTEÚDO COLETIVO.
      </p>
      <p>
        Se você aceitar ou concordar com estes Termos em nome de uma empresa ou
        outra pessoa jurídica, você declara e garante que tem autoridade para
        obrigar que a empresa ou outra entidade legal cumpra com estes Termos e,
        em tal caso, &quot;você&quot; e &quot;seu&quot; irá se referir e
        aplicar-se à empresa ou a outra pessoa jurídica.
      </p>
      <p>
        <strong>Modificações:</strong> A CLIENTCOM reserva-se o direito, a seu
        exclusivo critério, de modificar o aplicativo ou de modificar estes
        Termos, incluindo as Taxas de Serviço, a qualquer momento e sem aviso
        prévio. Se modificarmos estes Termos, poderemos divulgar a alteração no
        aplicativo ou fornecer um aviso da alteração por e-mail. Iremos também
        atualizar a &quot;Data da última atualização&quot; na parte superior
        destes Termos.
      </p>
      <p>
        As alterações aos Termos entrarão em vigor no momento da publicação.
      </p>
      <p>
        Seu acesso ou uso continuado da plataforma constituirá aceitação dos
        Termos alterados. Além disso, se os Termos alterados contiverem
        alterações significativas para os Membros existentes (diminuindo os
        direitos do usuário ou aumentando suas responsabilidades), iremos
        facultar-lhe um aviso antes das alterações entrarem em vigor. Se os
        Termos alterados não são aceitáveis para você, seu único recurso é parar
        de utilizar o aplicativo, e pedir o cancelamento da sua conta, pelo e-
        mail: CLIENTCOM.ATENDE@GMAIL.COM
      </p>
      <p>
        Se você não cancelar sua conta CLIENTCOM, e continuar acessando o
        aplicativo, será considerado que você aceitou as alterações.
      </p>
      <p>
        <strong>Quem pode usar o aplicativo:</strong> O aplicativo é destinado
        exclusivamente para pessoas maiores de 18 anos. Qualquer acesso ou
        utilização do aplicativo por menores de 18 anos é expressamente
        proibido. Ao acessar ou utilizar a plataforma, você declara e garante
        que tem mais de 18 anos de idade.
      </p>
      <p>
        <strong>Como funciona o aplicativo:</strong> A plataforma CLIENTCOM é
        disponibilizada para o usuário e vendedor, ambos tem que realizar um
        cadastro para que possam utilizar o mesmo, esse cadastro é GRATUITO para
        ambos. Após o cadastro o usuário pode dentro da plataforma contatar com
        vendedor esse já devidamente cadastrado para realizar orçamentos, tirar
        dúvidas e por fim contratar os serviços selecionados que precisar.
      </p>
      <p>
        Para o usuário o uso da plataforma não terá nenhum custo, podendo o
        mesmo baixar o aplicativo nas lojas de forma gratuita. Para o vendedor
        pessoa física ou pessoa jurídica que optar por se cadastrar dentro da
        plataforma, para prestar serviços deverá fornecer os dados de um cartão
        de crédito para cadastro, pois ao realizar atendimentos dentro da
        plataforma será cobrado um valor, no qual será debitado do cartão de
        crédito. Futuramente poderá ser cobrado um valor mensal do vendedor para
        o uso da plataforma.
      </p>
      <p>
        Valor esse que será devidamente informado na plataforma, e poderá sofrer
        alterações, será previamente anunciado ao prestador de serviços, podendo
        ele optar pelo prosseguimento do uso da plataforma ou o seu
        cancelamento, sem gerar prejuízo as partes. A plataforma poderá fornecer
        em alguns momentos de forma estratégica a gratuidade do uso da mesma,
        mas sem nenhuma obrigação.
      </p>
      <p>
        O Vendedor irá pagar o valor da taxa para poder realizar o atendimento
        ao usuário, independentemente se a venda for concretizada ou não, não
        tendo a responsabilidade alguma a plataforma CLIENTCOM.
      </p>
      <p>
        Após obter estas informações o usuário irá selecionar qual serviço tem
        interesse, e o formato que deseja ser atendido, se de forma presencial
        ou por telefone, e qual é a sua necessidade. Posteriormente após a
        escolha o telefone irá tocar simultaneamente em vários vendedores
        próximos ao cliente, até que um vendedor realize o atendimento.
      </p>
      <p>
        O Vendedor deverá baixar o aplicativo e se cadastrar fornecendo os seus
        dados como nome, telefone, email, empresa que representa e dados do
        cartão de crédito , além de uma foto respeitando as exigências da
        plataforma.
      </p>
      <p>
        Será possível ao usuário avaliar e dar uma nota para o atendimento que
        recebeu pelo vendedor, afim de trabalhar a qualidade dos atendimentos ao
        usuário. Na medida que o vendedor for recebendo nota pelo atendimento,
        ele poderá receber alguns benefícios, a critério da CLIENTCOM.
      </p>
      <p>
        Após a solicitação de atendimento o cliente registrado no aplicativo,
        poderá fazer novas solicitações quando necessitar.
      </p>
      <p>
        <strong>Registro de Conta:</strong> Ao baixar o aplicativo o usuário
        deve realizar o seu cadastro e criar uma conta para poder acessar o
        mesmo, para o cadastro deverá preencher com alguns dados como: e-mail, e
        número de CPF ou CNPJ, válidos.
      </p>
      <p>
        Usuário ao criar sua conta de usuário no aplicativo você declara, ao
        mesmo tempo e ciente das penalidades legais, que suas informações
        fornecidas são verdadeiras e corretas e que você as atualizará
        prontamente, caso elas se modifiquem. O não provimento correto destas
        informações é considerado quebra destes Termos de Uso e eventualmente
        poderá vir a ser considerado crime de falsidade ideológica (artigo 299
        do Código Penal) pelas autoridades Você declara que tem direito de
        divulgar suas informações de acesso de Conta de Terceiros para a
        CLIENTCOM e/ou concede o acesso a CLIENTCOM à sua Conta de Terceiros
        (incluindo, mas não limitando a, utilização para os fins aqui
        descritos), sem violação por sua parte de qualquer dos termos e
        condições que regem a utilização da Conta de Terceiros aplicáveis, e sem
        obrigação da CLIENTCOM a pagar qualquer taxa ou vinculação da CLIENTCOM
        a qualquer limitação de utilização imposta por esses prestadores de
        serviços de terceiros.
      </p>
      <p>
        A sua Conta CLIENTCOM será criada para a utilização do aplicativo com
        base nas informações pessoais.{' '}
      </p>
      <p>
        Você concorda em fornecer informações precisas, atualizadas e completas
        durante o processo de registro e atualizar tais informações para
        mantê-las precisas, atualizadas e completas.
      </p>
      <p>
        A CLIENTCOM se reserva o direito de suspender ou encerrar sua Conta e
        seu acesso ao aplicativo se você criar mais de 1 (uma) conta, ou se
        quaisquer informações fornecidas durante o processo de registro ou
        depois deste, demonstrar-se imprecisa, fraudulenta, incompleta ou não
        estiver atualizada ou estiver de qualquer outra forma em violação destes
        Termos de Serviço.
      </p>
      <p>
        A CLIENTCOM somente se responsabiliza pela confidencialidade da senha
        nos limites de seu banco de dados. Caso sua senha venha a ser utilizada
        por terceiro, a CLIENTCOM irá continuar considerando que as atividades
        desenvolvidas na sua conta foram feitas por você e são de sua
        responsabilidade, até que se comprove a violação. Por isso, é importante
        que você os informe imediatamente a quebra de confidencialidade de sua
        senha e/ou qualquer uso não autorizado de sua conta. Você é responsável
        por proteger a sua senha. Você concorda que você não vai revelar sua
        senha a terceiros.
      </p>
      <p>
        Salvo se expressamente autorizado por um recurso específico na
        CLIENTCOM, você não tem permissão para compartilhar sua Conta com
        ninguém nem permitir que outros acessem ou usem sua Conta. A CLIENTCOM
        poderá ativar recursos, a nosso critério, que permitam que outros
        Membros adotem determinadas medidas associadas à sua Conta, em seu nome
        com sua autorização expressa. Você concorda que vai assumir a
        responsabilidade exclusiva para quaisquer atividades ou ações sob sua
        Conta, autorizando ou não tais atividades ou ações. Você vai notificar
        imediatamente a CLIENTCOM de qualquer uso não autorizado da sua Conta.
      </p>
      <p>
        A clientcom não sera responsável por qualquer dano ou prejuízo
        resultante de suas escolhas e contratações realizadas com as empresas ou
        vendedores.
      </p>
      <p>
        lembrando que os valores serão disponibilizados dentro da plataforma e
        será avisado com antecedência da sua cobrança e sempre que for aceito o
        atendimento, será solicitado autorização para que o vendedor
      </p>
      <p>
        <strong>Anúncios:</strong> A CLIENTCOM se reserva o direito de, a
        qualquer momento e sem aviso prévio, remover ou impossibilitar o acesso
        a qualquer Anúncio por qualquer motivo, incluindo Anúncios que a
        CLIENTCOM, a seu exclusivo critério, considerar como censuráveis, por
        qualquer motivo, em violação a estes Termos ou às Políticas de
        privacidade, então em vigor ou de outra forma prejudiciais para o
        Aplicativo ou os Serviços.
      </p>
      <p>
        <strong>Nenhum endosso:</strong> A CLIENTCOM não garante qualquer
        informação disponibilizada no aplicativo. Você compreende que as Imagens
        são meramente ilustrativas. Os usuários e vendedores, por meio destes
        Termos, são solicitados a fornecer informações precisas, verídicas e
        fundamentadas mas a CLIENTCOM não faz nenhuma declaração de confirmação
        ou de endosso de qualquer pessoa ou suposta identidade ou antecedentes
        de qualquer um.
      </p>
      <p>
        Os dados, informações, descrições passadas através dos vendedores,
        destinam-se a constituir informações úteis para auxiliá-lo a tomar suas
        próprias decisões quando você avaliar e decidir o que é adequado para a
        sua situação na prática. E recomendado que você sempre tenha a devida
        diligência e cuidado quando estiver decidindo sobre qual melhor plano,
        pacote de serviços telecom. A CLIENTCOM não será responsável por
        qualquer dano ou prejuízo resultante de suas escolhas e contratações
        realizadas com as operadoras ou vendedores.
      </p>
      <p>
        Ao utilizar o aplicativo, você concorda que qualquer ação judicial ou
        responsabilidade civil que você procurar obter por ações ou omissões de
        integrantes ou outros terceiros será limitada a uma ação ou
        reivindicação contra os integrantes particulares ou de terceiros que lhe
        causaram danos. Você concorda em não tentar impor a responsabilidade em,
        ou ajuizar recurso legal contra o CLIENTCOM com relação a tais ações ou
        omissões.
      </p>
      <p>
        <strong>Reserva Geral:</strong> Você concorda que não usará a plataforma
        para fazer-se passar por outra pessoa ou acessar o aplicativo com nome/
        conta de outro indivíduo, sob pena de incorrer na prática dos crimes de
        falsidade.
      </p>
      <p>
        Você concorda em não divulgar ou distribuir, através de nenhum meio,
        quaisquer informações, imagens, desenhos, gráficos, tabelas, textos,
        símbolos, logos, marcas ou qualquer outro conteúdo da plataforma, sem a
        permissão prévia por escrito da CLIENTCOM.
      </p>
      <p>
        Você concorda em não alterar ou excluir qualquer parte da plataforma.
      </p>
      <p>
        Você concorda em não acessar nenhum conteúdo da plataforma através de
        qualquer tecnologia ou meio que não os que sejam autorizados e providos
        pela CLIENTCOM.
      </p>
      <p>
        Você concorda em não usar a plataforma para quaisquer fins comerciais,
        sem a prévia autorização por escrito da CLIENTCOM. Usos comerciais
        proibidos incluem quaisquer das seguintes ações tomadas sem a aprovação
        expressa da CLIENTCOM.
      </p>
      <ul>
        <li>
          Venda de acesso ao aplicativo ou seus serviços relacionados em outra
          plataforma;
        </li>
        <li>
          Uso do aplicativo ou seus serviços relacionados com o objetivo
          primário de obter propaganda ou receitas com assinaturas;
        </li>
        <li>
          Publicidade, no aplicativo ou qualquer plataforma de terceiros,
          orientada para o conteúdo da CLIENTCOM
        </li>
        <li>
          Uso do aplicativo ou seus serviços relacionados buscando competir ou
          desviar o mercado da CLIENTCOM
        </li>
        <li>
          Aliciamento, para fins comerciais, de quaisquer usuários do
          aplicativo.
        </li>
      </ul>
      <p>
        No uso do aplicativo você agirá em conformidade com os termos e
        condições destes Termos de Uso e com todas as leis e regulamentações
        nacionais e internacionais aplicáveis.
      </p>
      <p>Você concorda também em não:</p>
      <ul>
        <li>
          Acessar ou tentar acessar qualquer serviço ou área do aplicativo que
          você não estiver autorizado a usar;
        </li>
        <li>
          Exercer engenharia reversa em qualquer parte do aplicativo fazer
          qualquer coisa que possa revelar o código fonte ou violar medidas
          empregadas para evitar ou limitar o acesso a qualquer área, conteúdo
          ou código do aplicativo;
        </li>
        <li>
          Enviar ao aplicativo códigos maldosos, ilegais ou prejudiciais, tais
          como vírus, “spyware”, “adware” ou outro código que possa interferir
          no funcionamento do mesmo;
        </li>
        <li>
          Fazer qualquer coisa que possa impor uma carga significativa na
          infraestrutura do aplicativo ou então interferir com a operação normal
          do mesmo;
        </li>
      </ul>
      <p>
        <strong>Relação com websites de Terceiros:</strong> O aplicativo
        CLIENTCOM pode conter links para plataforma de terceiros que não sejam
        de propriedade ou controle do aplicativo. A CLIENTCOM não tem controle e
        não assume responsabilidade pelo conteúdo, políticas de privacidade ou
        práticas de qualquer uma destas plataforma. Além disso, a CLIENTCOM não
        pode e não fará censura e nem editará o conteúdo de nenhuma desta
        plataforma. Ao usar o nosso aplicativo você expressamente isenta a
        CLIENTCOM de todas as responsabilidades do uso que você fizer de
        qualquer plataforma de terceiros. Recomendamos que você fique atento
        quando sair da CLIENTCOM para alguma outra plataforma e leia seus termos
        e políticas de privacidade ao acessá-lo.
      </p>
      <p>
        <strong>Comunicações entre as partes envolvidas:</strong> Quando você
        opta pelo atendimento e contratar os serviços pelo aplicativo, está
        entrando em um relacionamento de negócio com o respectivo vendedor. Você
        concorda que, como um resultado deste relacionamento, o consultor pode
        contatá-lo via e-mail, telefone, endereço ou outros meios, para lhe
        passar informações, notificá-lo sobre alguma alteração, ou comunicar
        outras informações relevantes.
      </p>
      <p>
        Visando mantê-lo sempre bem informado a CLIENTCOM pode enviar e-mails
        com comunicações relevantes com relação à sua dúvida. Você concorda que
        estas comunicações por e-mail não constituem “e-mails não solicitados”
        e, como tal, não estão sujeitos aos termos da política de cancelamento
        de assinatura do aplicativo.{' '}
      </p>
      <p>
        Você também concorda que pode não receber estas mensagens eventualmente,
        devido a erros do servidor, problemas de tráfego da Internet, filtros de
        e-mail ou quaisquer outras circunstâncias. Falhas no recebimento ou na
        leitura de uma mensagem sobre uma reserva não o isentam de maneira
        alguma de cumprir suas responsabilidades com o prestador de serviço
        (comparecimento no horário agendado ou cancelamento da reserva, de
        acordo com a política de cancelamentos do prestador de serviços).
      </p>
      <p>
        <strong>Propriedade do Conteúdo do aplicativo e Marcas:</strong> O
        conteúdo do aplicativo (“Conteúdo”) e as marcas e logomarcas incluídas
        no aplicativo (“Marcas”) são propriedades da CLIENTCOM, sujeitos à Lei
        9.279/96 (Lei de propriedade industrial), Lei 9.610/98 (Lei de Direitos
        Autorais) e demais normas internacionais ligadas à propriedade
        intelectual. O conteúdo do aplicativo é provido para você conforme
        implementado na Internet, para conter suas informações e para seu uso
        pessoal somente. Não pode ser baixado, copiado, reproduzido,
        distribuído, transmitido, exibido, vendido, licenciado ou explorado de
        outras formas para quaisquer outros propósitos sem o consentimento
        prévio por escrito dos seus proprietários.
      </p>
      <p>
        Você concorda em não usar, copiar ou distribuir qualquer parte do
        Conteúdo, a não ser conforme expressamente permitido por estes Termos de
        Uso. O uso, cópia ou distribuição do Conteúdo com fins comerciais é
        proibido. Você não pode copiar, distribuir, alterar, mesclar, adaptar ou
        traduzir a plataforma. Não poderá também modificar a plataforma ou criar
        trabalhos derivativos baseados na plataforma, além de não poder vender,
        alugar, fazer leasing ou sublicenciar a plataforma.
      </p>
      <p>
        Você concorda em não burlar, desabilitar ou interferir nas
        implementações de segurança da a plataforma ou implementações que
        previnam ou restrinjam o uso ou cópia de qualquer parte do Conteúdo ou
        que imponham limitações no uso do aplicativo ou de seu Conteúdo.
      </p>
      <p>
        <strong>Política de cancelamento de conta e acesso do usuário:</strong>{' '}
        A CLIENTCOM pode cancelar um acesso de usuário a seu aplicativo e pode
        também cancelar uma conta de usuário, caso seja identificado que o
        usuário violou estes Termos de Uso.
      </p>
      <p>
        O usuário pode a qualquer momento cancelar a sua conta no aplicativo
        CLIENTCOM, sem correr nenhum prejuízo ou danos.
      </p>
      <p>
        <strong>Ressalvas sobre Garantia:</strong> Você concorda que seu uso do
        aplicativo será por sua conta e risco e que os serviços são providos da
        forma como apresentados e na disponibilidade possível. A CLIENTCOM não
        dá garantia sobre a veracidade ou completeza do conteúdo do aplicativo
        ou do conteúdo de quaisquer sites ligados a ele e não assume nenhuma
        responsabilidade por quaisquer (a) erros, enganos ou falhas no conteúdo,
        (b) perda pessoal ou dano de propriedade, de qualquer maneira que seja,
        resultante do seu acesso e uso do nosso aplicativo, (c) qualquer acesso
        não autorizado ou uso dos nossos servidores seguros e/ou todas as
        informações pessoais e/ou informações financeiras armazenadas, (d)
        quaisquer bugs, vírus, cavalos de Tróia ou semelhantes, que podem ser
        transmitidos para ou através do nossa plataforma por terceiros e/ou (e)
        quaisquer erros ou omissões em qualquer conteúdo ou por qualquer perda
        ou dano de qualquer tipo incorrido como resultado do uso de qualquer
        conteúdo postado, enviado por e-mail, transmitido ou de outra forma
        disponibilizado através da plataforma.
      </p>
      <p>
        A CLIENTCOM não garante, não endossa, nem assume responsabilidade por
        qualquer produto ou serviço divulgado ou oferecido por terceiros através
        do aplicativo ou qualquer site “linkado” a ele ou exibido em qualquer
        banner ou outro tipo de propaganda.
      </p>
      <p>
        A CLIENTCOM não será parceira nem de modo algum responsável por
        monitorar qualquer transação entre você e consultores e/ ou terceiros.
        Como na compra de um produto ou serviço através de qualquer meio ou em
        qualquer ambiente, você deve usar o seu bom senso e ter cautela quando
        apropriado.
      </p>
      <p>
        A CLIENTCOM se reserva o direito de mudar, descontinuar, restringir ou
        bloquear o acesso a qualquer parte da plataforma a qualquer momento.
      </p>
      <p>
        A CLIENTCOM não é responsável por prover suporte técnico ao usuário,
        acerto de erros de programas, documentação ou qualquer outro suporte da
        plataforma.
      </p>
      <p>
        <strong>Indenização:</strong> Você concorda em defender, indenizar e
        proteger a CLIENTCOM e seus agentes, diretores e funcionários contra
        quaisquer reclamações, danos, obrigações, perdas, responsabilidades,
        custos ou débitos e despesas (incluindo, mas não limitado, taxas de
        advogados), que surgirem a partir de: (a) seu uso e acesso a plataforma;
        (b) sua violação de qualquer termo destes Termos de Uso; (c) sua
        violação de qualquer direito de terceiros, incluindo qualquer
        propriedade de copyright, propriedade ou direito de privacidade. Esta
        obrigação de defesa e indenização sobreviverá a estes Termos de Uso e ao
        seu uso da plataforma.
      </p>
      <p>
        <strong>Privacidade:</strong> Você concorda que a Política de
        Privacidade da CLIENTCOM (conforme possa ser atualizada de tempos em
        tempos) rege a coleta e utilização de suas informações pessoais. Sujeito
        a sua conformidade com os termos e condições destes Termos da CLIENTCOM,
        lhe concede uma licença limitada, não exclusiva e intransferível, para
        (i) acessar e visualizar qualquer Conteúdo CLIENTCOM exclusivamente para
        fins pessoais e não comerciais e (ii) acessar e visualizar qualquer
        Conteúdo de Membro para o qual você tem permissão de acesso,
        exclusivamente para fins pessoais e não comerciais. Você não tem direito
        de sublicenciar os direitos de licença concedidos nesta seção.
      </p>
      <p>
        Você não deve usar, copiar, adaptar, modificar, preparar trabalhos
        derivados com base em distribuir, licenciar, vender, transferir, exibir
        publicamente, executar publicamente, transmitir, difundir ou de outra
        forma explorar o aplicativo, salvo conforme expressamente permitido
        nestes Termos. Nenhuma licença ou direitos são concedidos por implicação
        ou de outra forma sob quaisquer direitos de propriedade intelectual
        pertencentes ou controlados pela CLIENTCOM ou seus licenciadores, salvo
        para as licenças e direitos expressamente concedidos nestes Termos.
      </p>
      <p>
        <strong>
          Titularidade da propriedade intelectual e Notificações de direitos:
        </strong>{' '}
        O Aplicativo, ou Conteúdo Coletivo são protegidos por direitos autorais,
        marcas registradas. Você reconhece e concorda que o aplicativo, e os
        Conteúdos Coletivos, incluindo todos os direitos de propriedade
        intelectual associados, são propriedade exclusiva da CLIENTCOM e seus
        licenciadores. Você não deve remover, alterar ou ocultar nenhum direito
        autoral, marca comercial, marca de serviço ou outros direitos de
        propriedade incorporados ou que acompanhem o aplicativo, ou o Conteúdo
        coletivo.
      </p>
      <p>
        <strong>Conteúdo de Membros:</strong> Nós poderemos, a nosso exclusivo
        critério, permitir que você possa postar, carregar, publicar, enviar ou
        transmitir o Conteúdo de Membro. Ao disponibilizar qualquer Conteúdo de
        Membro através do aplicativo ou por meio das campanhas promocionais da
        CLIENTCOM, você concede à CLIENTCOM por meio deste instrumento uma
        licença mundial, irrevogável, perpétua (ou pelo prazo legal da
        proteção), não exclusiva, transferível, isenta de royalties, com o
        direito de sublicenciar, usar, visualizar, copiar, adaptar, traduzir,
        modificar, distribuir, licenciar, vender, transferir, exibir
        publicamente, executar publicamente, transmitir, stream, difundir,
        acessar, e de outro modo explorar tal Conteúdo de Membro em ou por meio
        do aplicativo, ou promovê-los ou comercializá-los. A CLIENTCOM não
        reivindica quaisquer direitos de propriedade sobre qualquer Conteúdo de
        Membro referido e nada presente nestes Termos será considerado para
        restringir quaisquer direitos que você possa ter de usar e explorar
        qualquer Conteúdo de Membro.
      </p>
      <p>
        Você reconhece e concorda que é o único responsável por todo o Conteúdo
        de Membro que você disponibilizar através do aplicativo, ou por meio das
        campanhas promocionais da CLIENTCOM. Assim, você declara e garante que:
        (i) ou você é o proprietário único e exclusivo de todo o Conteúdo de
        Membro que você disponibilizar através do aplicativo, ou você tem todos
        os direitos, licenças, autorizações e liberações necessárias para
        conceder à CLIENTCOM os direitos sobre o referido Conteúdo de Membro,
        conforme contemplado ao abrigo destes Termos, e (ii) nem o Conteúdo de
        Membro, nem a postagem, upload, publicação, apresentação ou transmissão
        do Conteúdo de Membro por você ou uso do Conteúdo de Membro pela
        CLIENTCOM (ou qualquer parte dele) no, através do, ou por meio do
        aplicativo ou campanhas promocionais da CLIENTCOM infringe, desapropria
        ou viola uma patente, direitos autorais, marca registrada, segredo
        comercial, direitos morais ou outros direitos proprietários ou de
        propriedade intelectual ou direitos de personalidade, tais como imagem
        ou privacidade, de terceiros, ou resulta na violação de qualquer lei ou
        regulamento aplicável.
      </p>
      <p>
        <strong>Política de Direitos Autorais:</strong> A CLIENTCOM respeita a
        lei de direitos autorais e espera que seus usuários façam o mesmo. É
        política da CLIENTCOM encerrar em circunstâncias adequadas as Contas dos
        Membros ou outros titulares de contas que infrinjam repetidamente ou se
        acredita ter repetidamente infringido os direitos dos detentores de
        direitos autorais.
      </p>
      <p>
        <strong>Rescisão imotivada:</strong> Você pode rescindir estes Termos a
        qualquer momento, enviando-nos um e-mail. Sem limitação dos nossos
        direitos especificados abaixo, a CLIENTCOM poderá rescindir estes Termos
        imotivadamente, a qualquer momento, fornecendo a você uma notificação
        com 30 dias de antecedência via e-mail para o seu endereço de e-mail
        registrado.
      </p>
      <p>
        <strong>Rescisão por violação, suspensão e outras medidas:</strong> A
        CLIENTCOM pode rescindir estes Termos imediatamente e sem notificação
        prévia se você tiver violado materialmente estes Termos ou as nossas
        Políticas, incluindo, sem limitação, qualquer violação das suas
        garantias descritas nestes Termos, você forneceu informações imprecisas,
        fraudulentas, desatualizadas ou incompletas durante o registro da Conta,
        ou processo de Anúncio ou posteriormente, você tiver violado as leis e
        regulamentos aplicáveis ou direitos de terceiros, ou se a CLIENTCOM
        acreditar de boa-fé que tal ação é razoavelmente necessária para
        proteger a segurança ou a propriedade de outros Membros, da CLIENTCOM ou
        de terceiros, para a prevenção de fraudes, avaliação de riscos, questões
        de segurança ou de investigação.
      </p>
      <p>
        Além disso a CLIENTCOM pode desativar ou atrasar Anúncios, avaliações ou
        outros Conteúdos de Membro, revogar de maneira temporária ou permanente
        qualquer status especial associado com a sua Conta, ou suspender de
        maneira temporária ou permanente a sua Conta, se você tiver violado
        estes Termos, incluindo violações materiais e não materiais e receber
        classificações insatisfatórias de usuários e consultores, a CLIENTCOM
        acreditar de boa-fé que tal ação é razoavelmente necessária para
        proteger a segurança ou propriedade dos Membros, da CLIENTCOM ou de
        terceiros, para a prevenção de fraudes, avaliação de riscos, questões de
        segurança ou de investigação.
      </p>
      <p>
        <strong>Permanência em vigor:</strong> Se você ou nós rescindirmos este
        Contrato, as cláusulas destes Termos que devam sobreviver razoavelmente
        à rescisão do Contrato permanecerão em vigor.
      </p>
      <p>
        <strong>Isenções:</strong> SE VOCÊ OPTAR POR UTILIZAR O APLICATIVO, VOCÊ
        RECONHECE E CONCORDA QUE A CLIENTCOM NÃO TEM A OBRIGAÇÃO DE REALIZAR
        VERIFICAÇÕES DE ANTECEDENTES OU REGISTRO DE CRIMES SEXUAIS DE QUALQUER
        MEMBRO, INCLUINDO, SEM LIMITAÇÃO, CONSULTORES/USUÁRIOS, MAS PODE
        PROCEDER AS VERIFICAÇÕES DE ANTECEDENTES OU REGISTRO DE CRIMES SEXUAIS,
        A NOSSO EXCLUSIVO CRITÉRIO, DESDE QUE PERMITIDO PELAS LEIS APLICÁVEIS E
        SE TIVERMOS INFORMAÇÕES SUFICIENTES PARA IDENTIFICARMOS UM MEMBRO.
      </p>
      <p>
        SE OPTARMOS POR REALIZAR ESSAS VERIFICAÇÕES, NA EXTENSÃO PERMITIDA PELA
        LEGISLAÇÃO APLICÁVEL, NÓS RENUNCIAMOS ÀS GARANTIAS DE QUALQUER TIPO,
        EXPRESSAS OU IMPLÍCITAS, DE QUE ESTES CONTROLES IDENTIFICARÃO ATOS
        ILÍCITOS PRÉVIOS POR PARTE DE UM USUÁRIO OU CONSULTORES QUE O USUÁRIO
        NÃO VAI SE ENVOLVER EM ATOS ILÍCITOS NO FUTURO.
      </p>
      <p>
        O APLICATIVO, É FORNECIDO &quot;COMO ESTÁ&quot;, SEM GARANTIA DE
        QUALQUER TIPO, EXPRESSA OU IMPLÍCITA. SEM LIMITAÇÃO A ESTES, A CLIENTCOM
        EXPLICITAMENTE SE EXONERA DE QUALQUER GARANTIA DE COMERCIALIZAÇÃO,
        QUALIDADE SATISFATÓRIA, DE ADEQUAÇÃO PARA UM PROPÓSITO ESPECÍFICO, USO
        PACÍFICO OU NÃO VIOLAÇÃO E QUAISQUER GARANTIAS DECORRENTES DE NEGOCIAÇÃO
        OU USO DE COMÉRCIO.
      </p>
      <p>
        A CLIENTCOM NÃO GARANTE QUE O APLICATIVO, INCLUINDO, SEM LIMITAÇÃO, OS
        ANÚNCIOS, INFORMAÇÕES, ATENDERÁ AOS REQUISITOS OU ESTARÃO DISPONÍVEIS EM
        UMA BASE ININTERRUPTA, SEGURA OU SEM ERROS.
      </p>
      <p>
        A CLIENTCOM NÃO FAZ GARANTIA EM RELAÇÃO À QUALIDADE DOS ANÚNCIOS,
        INFORMAÇÕES, CONSULTORES, OS SERVIÇOS OU CONTEÚDO COLETIVO OU A
        PRECISÃO, PONTUALIDADE, VERACIDADE, INTEGRIDADE OU CONFIABILIDADE DE
        QUALQUER CONTEÚDO COLETIVO OBTIDO ATRAVÉS DO APLICATIVO.
      </p>
      <p>
        NENHUM CONSELHO OU INFORMAÇÃO, VERBAL OU POR ESCRITO, OBTIDOS DA
        CLIENTCOM OU ATRAVÉS DO APLICATIVO, CRIARÁ QUALQUER GARANTIA QUE NÃO
        TENHA SIDO EXPRESSAMENTE FEITA NESTES TERMOS.
      </p>
      <p>
        VOCÊ É O ÚNICO RESPONSÁVEL POR TODAS AS SUAS COMUNICAÇÕES E INTERAÇÕES
        COM OUTROS USUÁRIOS/ CONSULTORES DO APLICATIVO, E COM OUTRAS PESSOAS COM
        AS QUAIS VOCÊ SE COMUNICA OU INTERAGE COMO RESULTADO DO SEU USO DO
        APLICATIVO.
      </p>
      <p>
        A CLIENTCOM NÃO FAZ NENHUMA REPRESENTAÇÃO OU GARANTIA QUANTO À CONDUTA
        DE USUÁRIOS/ CONSULTORES DO APLICATIVO, SUA COMPATIBILIDADE COM QUALQUER
        USUÁRIO ATUAL OU FUTURO DO APLICATIVO.
      </p>
      <p>
        VOCÊ CONCORDA EM TOMAR AS DEVIDAS PRECAUÇÕES EM TODAS AS COMUNICAÇÕES E
        INTERAÇÕES COM OUTROS USUÁRIOS DO APLICATIVO, E COM OUTRAS PESSOAS COM
        AS QUAIS VOCÊ SE COMUNICA OU INTERAGE COMO RESULTADO DE SUA UTILIZAÇÃO
        DO APLICATIVO, INCLUINDO, MAS NÃO LIMITANDO A COSULTORES,
        PARTICULARMENTE SE DECIDIR ENCONTRAR-SE OFF-LINE OU EM PESSOA,
        INDEPENDENTEMENTE DE TAIS ENCONTROS SEREM ORGANIZADOS PELA CLIENTCOM.
      </p>
      <p>
        A CLIENTCOM EXPLICITAMENTE NÃO SE RESPONSABILIZA POR QUALQUER ATO OU
        OMISSÃO DE QUALQUER CONSULTOR.
      </p>
      <p>
        <strong>Limitação de Responsabilidade:</strong> VOCÊ RECONHECE E
        CONCORDA QUE, NO LIMITE PERMITIDO POR LEI, OS RISCOS DECORRENTES DO
        ACESSO E UTILIZAÇÃO DO APLICATIVO, E QUALQUER CONTATO QUE VOCÊ TENHA COM
        OUTROS USUÁRIOS E CONSULTORES DA CLIENTCOM PESSOALMENTE OU ON-LINE, SÃO
        DE SUA RESPONSABILIDADE.
      </p>
      <p>
        NEM A CLIENTCOM NEM QUALQUER OUTRA PARTE ENVOLVIDA NA CRIAÇÃO, PRODUÇÃO
        OU DISPONIBILIZAÇÃO DO APLICATIVO, SERÁ RESPONSÁVEL POR QUAISQUER DANOS
        INCIDENTAIS, ESPECIAIS, EXEMPLARES OU CONSEQUENCIAIS, INCLUINDO LUCROS
        CESSANTES, DE DADOS OU PERDA DE FUNDO DE COMÉRCIO, INTERRUPÇÃO DO
        SERVIÇO, DANOS EM COMPUTADOR, CELULARES OU FALHA NO SISTEMA, OU DO CUSTO
        SUBSTITUIÇÃO DE PRODUTOS OU SERVIÇOS, OU POR QUAISQUER DANOS PESSOAIS OU
        CORPORAL OU MORAIS OU ANGÚSTIA EMOCIONAL DECORRENTES DE OU EM CONEXÃO
        COM ESTAS CONDIÇÕES, DO USO OU DA IMPOSSIBILIDADE DE UTILIZAR O
        APLICATIVO DECORRENTES DE QUAISQUER COMUNICAÇÕES, INTERAÇÕES OU
        ENCONTROS COM OUTRAS PESSOAS COM AS QUAIS VOCÊ SE COMUNICA OU INTERAGE
        COMO RESULTADO DO SEU USO DO APLICATIVO.
      </p>
      <p>
        <strong>Notificações:</strong> Quaisquer notificações ou outras
        comunicações permitidas ou requeridas nos termos deste instrumento,
        incluindo as modificações em relação a estes Termos, serão feitas por
        escrito e entregues pela CLIENTCOM (i) via Comunicação (em cada caso,
        para o endereço de e-mail ou número de telefone que você fornecer) ou
        (ii) por publicação no aplicativo. Para notificações enviadas por uma
        Comunicação, a data de recebimento será considerada como sendo a data em
        que tal notificação for transmitida.
      </p>
      <p>
        <strong>Geral:</strong> O fato da CLIENTCOM por qualquer motivo não
        exercer ou executar qualquer direito ou disposição destes Termos não
        constitui uma renúncia à aplicação futura desse direito ou cláusula. A
        renúncia de qualquer direito ou cláusula somente será eficaz se for
        realizada por escrito e assinada por um representante devidamente
        autorizado da CLIENTCOM Exceto quando expressamente estabelecido nestes
        Termos, o exercício por qualquer das partes de qualquer medida judicial
        sob estes Termos, será sem prejuízo de outras medidas ao abrigo destes
        Termos ou de outra forma. Se, por qualquer razão, um árbitro ou um
        tribunal de jurisdição competente considerar qualquer disposição destes
        Termos inválida ou inexequível, tal disposição será aplicada até o
        limite máximo permitido e as demais disposições destes Termos
        permanecerão em pleno vigor e efeito.
      </p>
      <p>
        <strong>TRATAMENTO DE DADOS PESSOAIS </strong>
      </p>
      <p>
        Para fins de preservação da privacidade do usuário de internet, as
        Partes comprometem-se a reter a menor quantidade possível de dados e
        registros e excluí-los (i) tão logo atingida a finalidade de seu uso ou
        (ii) se encerrado o prazo determinado por obrigação legal, conforme
        preceitua o disposto no artigo 13, §2º da Lei do Marco Civil da
        Internet. As Partes se obrigam a realizar o tratamento de dados pessoais
        de acordo com as disposições legais vigentes, bem como nos moldes da Lei
        13.709/2018, a Lei Geral de Proteção de Dados Pessoais (LGPD), visando
        dar efetiva proteção aos dados coletados de pessoas naturais que possam
        identificá-las ou torna-las identificáveis, utilizando-os de tais dados
        tão-somente para os fins necessários à consecução do objeto deste
        Contrato, ou nos limites do consentimento expressamente manifestado por
        escrito por seus respectivos titulares.
      </p>
      <p>
        Você declara inequívoca ciência de que é de sua exclusiva
        responsabilidade a utilização dos dados obtidos por meio da utilização
        da plataforma CLIENTCOM, sendo terminantemente vedada a utilização de
        tais informações para fins diversos daqueles relativos ao objeto da
        mesma, bem como outros fins ilícitos, ou que, de qualquer forma, atentem
        contra a moral e os bons costumes. A CLIENTCOM não será, em qualquer
        hipótese, responsabilizada pelo uso indevido por parte de você e/ou
        terceiros, com relação a dados armazenados em seus softwares e bancos de
        dados;
      </p>
      <p>
        As Partes se comprometem a adotar as melhores práticas para respeitar a
        legislação vigente e/ou que venha a entrar em vigor sobre proteção de
        dados, sendo certo que se adaptará, inclusive, à Lei nº 13.709/2018
        (“Lei de Proteção de Dados”) até a sua entrada em vigor.
      </p>
      <p>
        A CLIENTCOM deverá, considerando os meios tecnológicos disponíveis e
        adequados às suas atividades, a natureza dos dados armazenados e os
        riscos a que estão expostos, adotar medidas físicas e lógicas, de
        caráter técnico e organizacional, para prover confidencialidade e
        segurança dos dados de modo a evitar sua alteração, perda, subtração e
        acesso não autorizado, bem como a violação da privacidade dos sujeitos
        titulares dos dados.
      </p>
      <p>
        Eventual responsabilidade imputável à CLIENTCOM em razão de prejuízos de
        qualquer natureza suportados por você em razão de danos ligados ao
        tratamento de dados pessoais, fica limitada a 10% (dez por cento) do
        valor correspondente aos valores efetivamente faturados nos 12 (doze)
        últimos meses deste contrato, sem prejuízo da possibilidade de resolução
        do presente Contrato pela parte inocente.
      </p>
      <p>
        Caso a CLIENTCOM seja demandada por qualquer pessoa, autoridade ou
        entidade, pública ou privada, em razão de vazamento de dados resultantes
        de qualquer atuação, fica garantido à CLIENTCOM o direito à denunciação
        da lide, nos termos do artigo 125, II, do Código de Processo Civil.
      </p>
      <p>
        <strong>POLÍTICA DE PRIVACIDADE E USO DE INFORMAÇÕES DO USUÁRIO</strong>
      </p>
      <p>
        É política da CLIENTCOM respeitar a privacidade de seus usuários. Assim
        sendo, não monitorará, editará ou revelará nenhuma informação do usuário
        ou sobre o uso destes serviços pelo usuário, sem sua permissão,
        excetuando para o fim deste contrato e nas hipóteses de tal conduta ser
        necessária para: (a) proteger o interesse da CLIENTCOM ou de terceiros;
        (b) responder a eventual reclamação de que tal conteúdo viole direitos
        de terceiros; (c) identificar e resolver problemas técnicos; (d) cumprir
        procedimento legal, inclusive determinação judicial ou de qualquer órgão
        regulatório competente; (e) fazer cumprir os termos dos serviços ora
        prestados.
      </p>
      <p>
        POR OPÇÃO DO USUÁRIO, ESTE AUTORIZA EXPRESSAMENTE O CLIENTCOM, OU
        TERCEIRO POR ELA INDICADO, A UTILIZAR SEUS DADOS CADASTRAIS PARA FINS
        COMERCIAIS E DE MARKETING.
      </p>
      <p>
        Salvo, estipulação em contrário e prévia aceitação do usuário, a
        CLIENTCOM não armazenará informações pessoais disponíveis em sites de
        terceiros, tais como Twitter, Facebook, Linkedin e outros, para
        compartilhamento ou inclusão em suas bases de dados.
      </p>
      <p>
        <strong>Gerais:</strong> Estes Termos de Uso, junto com a Política de
        Privacidade e outras notas legais publicadas pela CLIENTCOM no
        aplicativo constituem um acordo entre você e a CLIENTCOM, no que se
        refere o aplicativo. Qualquer um dos itens providos por estes Termos de
        Uso que seja considerado inválido por alguma instância judicial não
        afetará a validade dos itens restantes destes Termos de Uso, que
        permanecerão com força e efeito legais.
      </p>
      <p>
        Você e a CLIENTCOM concordam e elegem o foro da comarca de RIO DE
        JANEIRO, Brasil, como sendo o competente para dirimir quaisquer dúvidas
        ou lides que possam surgir da interpretação e/ou aplicação destes Termos
        de Uso, com renúncia a quaisquer outros, por mais privilegiados que
        possam parecer.
      </p>
    </S.TermsDiv>
  </ModalInfo>
);

export default ModalTerms;
