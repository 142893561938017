import styled from 'styled-components';

export const GreyBg = styled.div`
  width: 100%;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 450px) {
    button {
      padding: 5px 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2rem;
  padding-top: 5rem;

  @media (max-width: 1024px) {
    padding-left: 0;
    padding-top: 0;
    padding: 2rem;
    align-items: center;
    text-align: center;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 25rem;
    height: auto;
  }

  @media (max-width: 1024px) {
    img {
      width: 15rem;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 53rem;

  @media (max-width: 425px) {
    width: auto;
  }
`;

export const PageTitle = styled.h1`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 5rem;
  color: #2d2d2d;
  max-width: 500px;
  margin-top: 8rem;
  line-height: 50px;

  strong {
    font-weight: 700;
    color: #ffb70f;
  }

  @media (max-width: 930px) {
    margin-top: 4rem;
  }

  @media (max-width: 450px) {
    font-size: 4.5rem;
    line-height: 60px;
    font-size: 3rem;
  }
`;

export const PageSubtitle = styled.p`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: #2d2d2d;
  max-width: 683px;
  margin-top: 3rem;

  @media (max-width: 375px) {
    margin-bottom: 40px;
    font-size: 1.4rem;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 375px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Button = styled.button`
  background-color: #ffb70f;
  margin-top: 30px;
  margin-bottom: 70px;
  padding: 10px 15px;
  border-radius: 34px;
  width: 30rem;
  margin-right: 30px;

  font-family: Calibri, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  color: #2d2d2d;

  @media (max-width: 375px) {
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 15px;
  }
`;

export const ManDiv = styled.div`
  position: relative;
  right: 0;
  width: 130rem;
  height: 58rem;
  border-radius: 50% 0 0 50%;
  margin-top: 2rem;
  background-color: #ffb70f;

  @media (max-width: 1120px) {
    bottom: -17rem;
  }

  @media (max-width: 1024px) {
    top: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 35rem;
    height: 40rem;
    border-radius: 0 0 45% 45%;
    overflow: hidden;

    div {
      bottom: -20px;
    }
  }

  @media (max-width: 400px) {
    max-width: 30rem;
    top: -4rem;

    div {
      bottom: -40px;
    }
  }
`;

export const DoctorsImage = styled.img`
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 95%;
  height: auto;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const BackgroundImageLogo = styled.img`
  transition: 300ms ease all;
  position: absolute;
  transform: rotate(45deg);
  width: 100%;
  height: auto;
  margin-left: -8rem;
  margin-top: 4rem;
  z-index: 1;

  @media (max-width: 930px) {
    margin-left: -3rem;
  }
`;
