import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LogoColored } from '~/assets/icons';

import * as S from './styles';
import { Doctors } from '~/assets/images/health';

const HomeTitle: React.FC = () => {
  const [changeTitle, setChangeTitle] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setChangeTitle(!changeTitle);
    }, 3000);
  }, [changeTitle]);

  return (
    <S.GreyBg>
      <S.Content>
        <S.LogoContainer>
          <img src={LogoColored} alt="Logo ClientCom" />
        </S.LogoContainer>
        <S.TitleContainer>
          <S.PageTitle>
            Procura Planos de Saúde
            <strong> para pessoa física ou pessoa juridica? </strong>
          </S.PageTitle>
        </S.TitleContainer>
        <S.PageSubtitle>
          Somos especializados na venda de planos de saúde de qualquer operadora
          em qualquer lugar do Brasil, conectamos você ao vendedor especializado
          mais próximo.
        </S.PageSubtitle>
        <S.ContainerButtons>
          <S.Button onClick={() => history.push('/atendimento#form')}>
            Clique aqui para ser atendido
          </S.Button>
        </S.ContainerButtons>
      </S.Content>
      <S.ManDiv>
        <S.DoctorsImage src={Doctors} alt="médicos multirraciais confiantes" />
      </S.ManDiv>
    </S.GreyBg>
  );
};

export default HomeTitle;
