import styled, { CSSProperties } from 'styled-components';

import { light } from '~/styles/themes';
import { breakpoints } from '~/styles/tools/breakpoints';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacings.large};

  @media (max-width: ${breakpoints.medium}) {
    padding: 0 1.6rem;
  }
`;

export const Title = styled.h1`
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 800;
`;

export const TableWrapper = styled.section`
  width: 100%;
  padding: 3.2rem 2rem 6.4rem 2rem;
  border-radius: 7px;
  border: 1px solid rgba(70, 70, 70, 0.1);
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: ${breakpoints.medium}) {
    padding: 4rem 1.6rem;
  }
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  column-gap: 6.4rem;
  align-items: center;
  padding: 0 2.4rem;
  margin-bottom: 4rem;

  @media (max-width: ${breakpoints.medium}) {
    padding: 0 1.6rem;
    flex-direction: column;
    margin-bottom: 1.6rem;
  }
`;

export const TableFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6.4rem;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    margin-top: 3.2rem;
  }
`;

export const DownloadTD: CSSProperties = {
  color: light.colors.primary,
  fontWeight: 'bold',
  textDecoration: 'underline',
};
