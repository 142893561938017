import { useState } from 'react';

import { useField } from '@unform/core';
import MaskedInput from 'react-text-mask';

import { Input as InputBase } from '~/components';
import { InputMaskProps, MaskType } from '~/models/Common';
import { convertMaskToInputMask } from '~/utils/functions';

const InputMask: React.FC<InputMaskProps> = ({ name, mask, type, ...rest }) => {
  const [myMask] = useState<MaskType>(() => {
    if (typeof mask === 'string') {
      const converted = convertMaskToInputMask(mask);
      return converted;
    }

    return mask;
  });

  // const [isBlur, setIsBlur] = useState(false);

  const { defaultValue } = useField(name);

  const phoneMask = (rawValue: string): (string | RegExp)[] => {
    const numbers = rawValue.replace(/\D/g, '');
    if (numbers.length <= 10) {
      return [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
    if (numbers.length === 11) {
      return [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  };

  // const handleBlur = (): void => {
  //   setIsBlur(true);
  // };

  // const handleFocus = (): void => {
  //   setIsBlur(false);
  // };

  return (
    <MaskedInput
      // onBlur={handleBlur}
      mask={type === 'tell' ? phoneMask : myMask}
      {...{ name, defaultValue }}
      {...rest}
      render={(ref, props) => <InputBase {...{ name, ref }} {...props} />}
    />
  );
};

export default InputMask;
