import styled, { css } from 'styled-components';
import { breakpoints } from '~/styles/tools/breakpoints';

interface IMenuMobileToggleProps {
  isActive?: boolean;
}

export const MenuMobileToggleWrapper = styled.button<IMenuMobileToggleProps>`
  display: flex;
  background: none;
  justify-content: flex-start;
  height: 30px;
  cursor: pointer;

  > span {
    display: none;
  }

  @media (max-width: ${breakpoints.small}) {
    > span {
      display: block;
      box-sizing: initial;
      transition: 0.3s;
      height: 30px;
      cursor: pointer;

      &:after,
      &:before {
        content: '';
        display: block;
        width: 4rem;
        border: 2px solid ${({ theme }) => theme.colors.primary};
        border-radius: 4px;
        transition: 0.3s;
      }

      &:after {
        margin-top: 0.8rem;
        box-shadow: 0 12px 0 0 ${({ theme }) => theme.colors.primary};
      }
    }

    ${({ isActive }) =>
      isActive &&
      css`
        > span {
          &:after {
            box-shadow: none;
            transform: rotate(45deg) translate(-3px, -5.25px);
            transition: 0.3s transform;
            margin-top: 3px;
          }

          &:before {
            transform: rotate(-45deg) translate(0, 0);
            transition: 0.3s transform;
            margin-top: 12px;
          }
        }
      `}
  }
`;
