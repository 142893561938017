import { ClipLoader as LoaderBase } from 'react-spinners';
import styled from 'styled-components';

export const Loader = styled(LoaderBase)`
  margin: 0 auto;
  position: fixed;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
`;
