import { RefObject } from 'react';

import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { getValidationErrors } from '~/utils/functions';

export default function handleYupValidationError(
  formRef: RefObject<FormHandles>,
  error: unknown,
): void {
  if (error instanceof Yup.ValidationError) {
    const errors = getValidationErrors(error);

    Object.values(errors).forEach((value: string) => {
      toast.error(`${value}`);
    });
    formRef.current?.setErrors(errors);
  }
}
