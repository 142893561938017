import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Options = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.8rem;
  border: 1px solid #c1c1c1;
  background: #f3f3f366 0% 0% no-repeat padding-box;
  z-index: 30;
`;

export const Loading = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Option = styled.button`
  width: 100%;
  height: 40px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
  text-align: left;

  &:first-child {
    border-radius: 0.8rem 0.8rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 0.8rem 0.8rem;
  }

  &:hover {
    background-color: #dee7ff;
  }
`;
