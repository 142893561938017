import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px 38px;
  background-color: #f3f3f3;
`;
export const Terms = styled.div`
  font-family: Calibri, sans-serif;

  h1 {
    font-size: 3rem;
    text-align: center;
  }

  h2 {
    margin-top: 2rem;
    font-size: 1.8rem;
    text-align: center;
  }

  p {
    margin-top: 2rem;
    font-size: 1.6rem;
    text-align: justify;
  }

  ul {
    font-size: 1.6rem;
    text-align: justify;
    margin-left: 1.5rem;
    li {
      margin-top: 1rem;
    }
  }
`;
