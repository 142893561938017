import styled from 'styled-components';

export const BlackBg = styled.div`
  width: 100%;
  max-height: 50rem;
  background-color: #2d2d2d;
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
  padding: 0 20px;
  position: relative;

  &:hover {
    #blackbglogo {
      transform: rotate(-70deg);
    }
  }

  @media (max-width: 930px) {
    flex-direction: column;
    max-height: none;
    align-items: center;
  }
`;

export const BlackBgImage = styled.div`
  width: 70rem;
  position: absolute;
  z-index: 1;
  height: 50rem;
  overflow: hidden;

  > img {
    position: relative;
    width: 100%;
    height: auto;
    top: -40rem;
    right: 2rem;
    transition: 300ms ease all;
  }
`;

export const WomanDiv = styled.div`
  position: relative;
  width: 100%;
  max-height: 50rem;
  display: flex;
  justify-content: center;
  align-self: center;
  z-index: 2;

  /* > video {
    position: relative;
    width: 100%;
    max-width: 563.16px;
    height: 80%;


    @media (max-width: 470px) {
      height: 100%;
    }
  } */

  > img {
    position: relative;
    width: 100%;
    max-width: 463.16px;
    //height: 80%;

    border-radius: 8px;

    @media (max-width: 470px) {
      height: 100%;
    }
  }

  @media (max-width: 930px) {
    min-height: 0;
    width: 40rem;
    top: 1rem;
    margin: 1rem 0 3rem 0;
  }

  @media (max-width: 470px) {
    width: 30rem;
    top: 2.6rem;
    max-height: none;
  }

  @media (max-width: 345px) {
    width: 25rem;
    top: 3rem;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;

  @media (max-width: 1040px) {
    max-width: 40rem;
  }
  @media (max-width: 930px) {
    max-width: 50rem;
  }
`;

export const WhiteTitle = styled.h1`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 2.8rem;
  color: #fff;
  margin-top: 4rem;
  z-index: 2;

  strong {
    font-size: 2.7rem;
  }

  @media (max-width: 930px) {
    margin-top: 0;
  }
  @media (max-width: 330px) {
    font-size: 2.5rem;
    strong {
      font-size: 2.4rem;
    }
  }
`;

export const WhiteText = styled.p`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #fff;
  margin-top: 3rem;
  margin-bottom: 5rem;
  max-width: 54rem;
  z-index: 2;
`;
