import React, { useState, useCallback, useEffect } from 'react';
import { LogoColored, LogoBigC } from '~/assets/icons';

import * as S from './styles';
import { BusinessTeam } from '~/assets/images/health';

const HomeTitle: React.FC = () => {
  const [changeTitle, setChangeTitle] = useState(true);
  const handleScrollTo = useCallback(() => {
    document.getElementById('formdiv')?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setChangeTitle(!changeTitle);
    }, 3000);
  }, [changeTitle]);

  return (
    <S.GreyBg>
      <S.Content>
        <S.LogoContainer>
          <img src={LogoColored} alt="Logo ClientCom" />
        </S.LogoContainer>
        <S.TitleContainer>
          <S.PageTitle>
            Contrate seu
            <strong>
              {' '}
              <br /> plano de saúde
            </strong>{' '}
            com atendimento de um
            <br /> consultor especializado.
          </S.PageTitle>
          {/* {changeTitle && (
            <S.PageTitle>
              Contrate seu
              <strong>
                {' '}
                <br /> plano de saúde
              </strong>{' '}
              com atendimento de um
              <br /> consultor especializado.
            </S.PageTitle>
          )}
          {!changeTitle && (
            <S.PageTitle>
              Contrate{' '}
              <strong>
                internet
                <br /> fibra
              </strong>{' '}
              com atendimento
              <br /> prioritário.
            </S.PageTitle>
          )} */}
        </S.TitleContainer>
        <S.PageSubtitle>
          Receba <strong>orçamento</strong> de varias empresas de forma{' '}
          <strong>rápida</strong> e<strong> segura.</strong>
        </S.PageSubtitle>
        <S.PageSubtitle className="textSpan">
          <span>100% gratuito!</span>
        </S.PageSubtitle>
        <S.CustomButton
          textStyle={{ fontWeight: 400 }}
          onClick={handleScrollTo}
        >
          Solicitar atendimento <strong>gratuito</strong>
        </S.CustomButton>
      </S.Content>
      <S.ManDiv>
        <S.ManImage src={BusinessTeam} alt="equipe de negócios" />
        <div>
          <S.BackgroundImageLogo
            src={LogoBigC}
            alt="ClientCom Logo"
            id="backgroundlogo"
          />
        </div>
      </S.ManDiv>
    </S.GreyBg>
  );
};

export default HomeTitle;
