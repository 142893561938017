import styled from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacings.large};

  @media (max-width: ${breakpoints.medium}) {
    padding: 3.2rem 1.6rem;
  }
`;

export const OperatorInfo = styled.section`
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  display: flex;
  gap: 2.4rem;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const OperatorInfoImgBox = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.colors.primary};
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OperatorInfoImg = styled.img`
  max-width: 80px;
  max-height: 80px;
  border-radius: 50%;
`;

export const OperatorInfoData = styled.div`
  max-width: 45rem;
  width: 100%;
`;

export const OperatorInfoDataHeader = styled.div`
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 444px;
    background: ${({ theme }) => theme.colors.primary};
    margin: 1.6rem 0;

    @media (max-width: ${breakpoints.medium}) {
      width: 100%;
    }
  }
`;

export const OperatorInfoDataList = styled.ul`
  display: flex;
  justify-content: center;
  column-gap: 2.4rem;
  row-gap: 1.6rem;

  list-style: none;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const OperatorInfoDataItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const OperatorInfoDataTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 800;
`;

export const OperatorInfoDataValue = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 400;
`;

export const OperatorInfoDataName = styled.h2`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

export const TableWrapper = styled.section`
  width: 100%;
  padding: 3.2rem 2rem 6.4rem 2rem;
  border-radius: 7px;
  border: 1px solid rgba(70, 70, 70, 0.1);
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: ${breakpoints.medium}) {
    padding: 4rem 1.6rem;
  }
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  column-gap: 6.4rem;
  align-items: center;
  padding: 0 2.4rem;
  margin-bottom: 4rem;
`;

export const TableFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6.4rem;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2.4rem;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;
