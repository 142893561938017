import { ReactElement, useCallback } from 'react';

import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';

import { Container } from './styles';

export interface PageOptionsRequest {
  page: number;
  perpage?: number;
}

interface PaginationProps {
  pageRangeDisplayed: number;
  pageCount: number;
  setPageOptions: (options: PageOptionsRequest) => void;
}

export interface HandlerPageChange {
  ({ selected }: { selected: number }): void;
}

function Pagination({
  setPageOptions,
  pageCount,
  pageRangeDisplayed,
  ...props
}: PaginationProps): ReactElement {
  const handlePage: HandlerPageChange = useCallback(
    ({ selected }) => {
      setPageOptions({
        page: selected + 1,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setPageOptions],
  );

  const nextClassName = pageCount === 0 ? 'disabled' : 'item control';

  return (
    <>
      {pageCount > 1 && (
        <Container>
          <ReactPaginate
            containerClassName="container"
            previousLabel={<FiChevronLeft />}
            nextLabel={<FiChevronRight />}
            {...{ nextClassName, pageCount, pageRangeDisplayed }}
            pageClassName="item"
            activeClassName="active"
            previousClassName="item"
            nextClassName="item"
            breakClassName="item"
            disabledClassName="disabled"
            onPageChange={handlePage}
            {...props}
            marginPagesDisplayed={2}
          />
        </Container>
      )}
    </>
  );
}

export default Pagination;
