import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  width: 100%;
  min-height: 130rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  z-index: 3;

  @media (max-width: 1190px) {
    min-height: 110rem;
  }
  @media (max-width: 930px) {
    min-height: 95rem;
  }
  @media (max-width: 500px) {
    min-height: 80rem;
  }
`;

export const OrangeBg = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
`;

export const BgImage = styled.div`
  width: 100%;
  z-index: -1;

  img {
    position: absolute;
    top: 10rem;
    left: -3rem;
    max-width: 65vw;
    min-width: 60rem;
  }

  @media (max-width: 600px) {
    img {
      left: -10rem;
      top: 12rem;
    }
  }
  @media (max-width: 500px) {
    img {
      top: 14.5rem;
      max-width: 100%;
      min-width: 30rem;
      width: 100%;
      left: 0;
    }
  }

  @media (max-width: 370px) {
    img {
      top: 13.3rem;
      max-width: 35rem;
      min-width: 30rem;
      width: 35rem;
      left: 0;
    }
  }
`;

export const OrangeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20rem;
  margin-bottom: 5rem;

  h1 {
    font-family: Calibri, sans-serif;
    font-weight: 400;
    font-size: 2.35rem;
    letter-spacing: -0.52px;
    color: #fff;

    max-width: 80rem;

    /* strong {
      color: #000;
    } */

    .toUppercase {
      text-transform: uppercase;
    }
  }

  img {
    width: 3.5rem;
    height: auto;
    margin-right: 2rem;
  }

  @media (max-width: 1300px) {
    h1 {
      max-width: 45rem;
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 1.8rem;
      width: 80%;
    }
    img {
      width: 3rem;
    }
  }

  @media (max-width: 430px) {
    margin-top: 17rem;
    margin-bottom: 2rem;
    h1 {
      font-size: 1.6rem;
    }
    img {
      width: 2.5rem;
    }
  }

  @media (max-width: 370px) {
    margin-top: 15rem;
    margin-bottom: 2rem;
    h1 {
      font-size: 1.5rem;
    }
    img {
      width: 2.5rem;
    }
  }
`;

export const FormWrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormCard = styled(Form)`
  width: 700px;
  padding: 4rem;
  background-color: #fff;
  border-radius: 1.5rem;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 15px 17px #468ee526;
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Label = styled.h2`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.52px;
  color: #394761;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;

export const ModalText = styled.p`
  font-family: Calibri, sans-serif;
  font-weight: 300;
  font-size: 2.2rem;
  color: #fff;
  text-align: center;
`;
