import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import { breakpoints } from '~/styles/tools/breakpoints';
import { moveRightFadeIn } from '~/styles/tools/transitions';

interface ISidebarProps {
  isActive?: boolean;
}

interface IContentProps {
  sidebarIsActive?: boolean;
}

export const Container = styled.main`
  display: flex;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.gray};
  position: relative;
`;

export const Sidebar = styled.aside<ISidebarProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 169px;
  padding: 3rem 2.4rem;
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: 4;

  @media (max-width: ${breakpoints.medium}) {
    display: none;
    animation: ${moveRightFadeIn} 0.3s backwards;

    ${({ isActive }) =>
      isActive &&
      css`
        display: flex;
        animation: ${moveRightFadeIn} 0.3s forwards;
        flex-direction: column;
        align-items: center;
        max-width: 169px;
        padding: 3rem 2.4rem;
        background-color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        z-index: 4;
        height: 100%;
      `}
  }
`;

export const SidebarLogoLink = styled(Link)``;

export const SidebarLogo = styled.img`
  width: 132px;
  height: 37px;
  margin-bottom: 6.4rem;
`;

export const SidebarNav = styled.nav``;

export const SidebarNavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SidebarNavItem = styled.li`
  list-style: none;

  &:first-child:before {
    content: '';
    display: block;
    width: 114px;
    height: 1px;
    background: ${({ theme }) => theme.colors.black};
    opacity: 0.2;
    margin: 0 0 1.2rem 0;
  }

  &:after {
    content: '';
    display: block;
    width: 114px;
    height: 1px;
    background: ${({ theme }) => theme.colors.black};
    opacity: 0.2;
    margin: 1.2rem 0;
  }
`;

export const SidebarNavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  text-align: center;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const SidebarNavLinkIcon = styled.img`
  width: 37px;
  height: 42px;
`;

export const Content = styled.section<IContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ sidebarIsActive }) =>
    sidebarIsActive &&
    css`
      opacity: 0.4;
    `}
`;

export const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  padding: 3.2rem 6.4rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 2px #00000029;
  z-index: 2;

  @media (max-width: ${breakpoints.medium}) {
    align-items: center;
    justify-content: space-between;
    padding: 2.4rem 1.6rem;
  }
`;

export const HeaderAvatar = styled.button`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  background: none;

  @media (max-width: ${breakpoints.medium}) {
    gap: 0;
  }
`;

export const HeaderAvatarUserName = styled.p`
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const HeaderAvatarImg = styled.img`
  @media (max-width: ${breakpoints.medium}) {
    margin-right: 0.8rem;
  }
`;

export const ContentSection = styled.section`
  width: 100%;
  overflow-y: scroll;
`;
