import styled from 'styled-components';

export const Title = styled.h1`
  font-family: Calibri, sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  letter-spacing: 0.68px;
  color: #394761;
`;

export const Subtitle = styled.p`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.46px;
  color: #394761;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 60rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
  @media (max-width: 550px) {
    flex-direction: column;
    margin-top: 2rem;
    button {
      margin-top: 1rem;
    }
  }
`;

export const TermsWrapper = styled.div`
  display: flex;
`;

export const Terms = styled.button`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  letter-spacing: 0.52px;
  color: ${({ theme }) => theme.colors.primary};
  outline: none;
  background-color: transparent;
  margin-top: 3px;

  &:hover {
    text-decoration: underline;
  }
`;
