import axios, { CancelTokenSource } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const getToken = (): CancelTokenSource => axios.CancelToken.source();

export const cleanRequests = (): void => axios.CancelToken.source().cancel();

export default api;
