import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 241px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  border: 1px solid #c1c1c1;
  margin-bottom: 20px;
  background: #f3f3f366 0% 0% no-repeat padding-box;
  padding-right: 2rem;

  > input {
    flex: 1;
    width: 100%;
    background: #f9f9f9;
    border: 0;
    border-radius: 0.8rem;
    padding: 5px 20px;

    color: #394761;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.52px;

    ::placeholder {
      color: #39476180;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: 0.52px;
    }
  }
`;
