import { ReactElement } from 'react';
import { Rating, RatingProps } from '@material-ui/lab';

interface StarRatingProps extends RatingProps {}

function StarRating({ ...rest }: StarRatingProps): ReactElement {
  return <Rating {...rest} />;
}

export default StarRating;
