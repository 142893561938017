import {
  ReactElement,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
  lazy,
  Suspense,
} from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSProperties } from 'styled-components';

import { Table, Button, Pagination, Loading } from '~/components';
import { PageOptionsRequest } from '~/components/Pagination';
import { IPagination } from '~/models/Common';
import { IOperator } from '~/models/Operator';
import { ISeller } from '~/models/Seller';
import { AdminService } from '~/services';
import { light } from '~/styles/themes';
import { handleFormatDate } from '~/utils/functions';

import * as S from './styles';

// lazy components
const EditOperatorModal = lazy(() => import('./EditOperatorModal'));

interface IOperatorDetailParamsProps {
  id: string;
}

function OperatorDetail(): ReactElement {
  const [editOperatorModal, setEditOperatorModal] = useState(false);
  const [operator, setOperator] = useState<IOperator>();
  const [sellers, setSellers] = useState<IPagination<ISeller[]>>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: '0',
  });
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const router = useHistory();
  const { id } = useParams<IOperatorDetailParamsProps>();

  const backButtonStyle = useRef<CSSProperties>({
    borderRadius: '10px',
    maxWidth: '234px',
  });

  const OperatorImageMemo = useMemo(
    () => (
      <S.OperatorInfoImg
        src={operator?.image_url ? operator?.image_url : ''}
        alt={operator?.name}
        title={operator?.name}
      />
    ),
    [operator],
  );

  const handleGoBack = useCallback(() => {
    router.goBack();
  }, [router]);

  const handleGetOperatorById = useCallback(async () => {
    try {
      const response = await AdminService.getOperator(id);

      setOperator(response.data);
    } catch (error) {
      toast.error(`Lead não encontrado`);
      handleGoBack();
    }
  }, [handleGoBack, id]);

  const handleSwapOperatorStatus = useCallback(async () => {
    try {
      await AdminService.putUpdateOperatorStatus(
        {
          status: !operator?.status,
        },
        id,
      );
      toast.success('Status da operadora atualizado.');
      handleGetOperatorById();
    } catch (error) {
      toast.error('Não foi possível atualizar o status da operadora.');
    }
  }, [handleGetOperatorById, id, operator]);

  const handleGetSellers = useCallback(async () => {
    try {
      let response;

      if (id) {
        response = await AdminService.getOperatorSellers(id, pageOptions);

        setSellers(response.data);
      }
    } catch (error) {
      toast.error(`Não foi possível carregar os vendedores`);
      handleGoBack();
    }
  }, [handleGoBack, id, pageOptions]);

  const handleNavigateToSellerDetail = useCallback(
    (sellerId: string) => {
      router.push(`/admin/sellers/${sellerId}`);
    },
    [router],
  );

  const handleOpenEditOperatorModal = useCallback(() => {
    setEditOperatorModal(true);
  }, []);

  useEffect(() => {
    handleGetOperatorById();
  }, [handleGetOperatorById, editOperatorModal]);

  useEffect(() => {
    handleGetSellers();
  }, [handleGetSellers, operator]);

  const SellersTableMemo = useMemo(
    () => (
      <Table
        noItems={sellers?.data?.length === 0}
        noItemsMessage="Nenhum vendedor encontrado."
        tableHeadChildren={() => (
          <>
            <th>Nome do vendedor</th>
            <th>Data</th>
            <th>
              Total:{' '}
              {sellers.data?.length === 0
                ? 'Nenhum vendedor'
                : `${sellers.data?.length} vendedor${
                    sellers.data?.length === 1 ? '' : 'es'
                  }`}
            </th>
          </>
        )}
      >
        {sellers?.data?.map((seller) => (
          <tr
            key={seller.id}
            onClick={() => handleNavigateToSellerDetail(seller.id)}
          >
            <td>{seller.user.name}</td>
            <td>{handleFormatDate(seller.created_at)}</td>
            <td />
          </tr>
        ))}
      </Table>
    ),
    [handleNavigateToSellerDetail, sellers],
  );

  return (
    <>
      <S.Container>
        <S.OperatorInfo>
          <S.OperatorInfoImgBox>{OperatorImageMemo}</S.OperatorInfoImgBox>
          <S.OperatorInfoData>
            <S.OperatorInfoDataHeader>
              <S.OperatorInfoDataList>
                <S.OperatorInfoDataItem>
                  <S.OperatorInfoDataTitle>Operadora:</S.OperatorInfoDataTitle>
                  <S.OperatorInfoDataValue>
                    {operator?.name}
                  </S.OperatorInfoDataValue>
                </S.OperatorInfoDataItem>
                <S.OperatorInfoDataItem>
                  <S.OperatorInfoDataTitle
                    style={{
                      color: operator?.status
                        ? light.colors.green
                        : light.colors.red,
                    }}
                  >
                    Status:
                  </S.OperatorInfoDataTitle>
                  <S.OperatorInfoDataValue
                    style={{
                      color: operator?.status
                        ? light.colors.green
                        : light.colors.red,
                    }}
                  >
                    {operator?.status ? 'Ativo' : 'Inativo'}
                  </S.OperatorInfoDataValue>
                </S.OperatorInfoDataItem>
              </S.OperatorInfoDataList>
            </S.OperatorInfoDataHeader>
            <S.OperatorInfoDataTitle as="h1" style={{ textAlign: 'center' }}>
              Vendedores Cadastrados
            </S.OperatorInfoDataTitle>
          </S.OperatorInfoData>
        </S.OperatorInfo>
        <S.TableWrapper>
          <S.TableHeader>{SellersTableMemo}</S.TableHeader>
          <S.TableFooter>
            <S.ButtonGroup>
              <Button
                onClick={handleGoBack}
                containerStyle={backButtonStyle.current}
              >
                Voltar
              </Button>
              <Button
                onClick={handleOpenEditOperatorModal}
                containerStyle={backButtonStyle.current}
              >
                Editar
              </Button>
              <Button
                onClick={handleSwapOperatorStatus}
                containerStyle={backButtonStyle.current}
              >
                {operator?.status ? 'Desabilitar' : 'Habilitar'} operadora
              </Button>
            </S.ButtonGroup>
            <Pagination
              pageCount={sellers.lastPage ?? 0}
              pageRangeDisplayed={pageOptions.page}
              {...{ setPageOptions }}
            />
          </S.TableFooter>
        </S.TableWrapper>
      </S.Container>

      <Suspense fallback={<Loading loading />}>
        <EditOperatorModal
          modalIsVisible={editOperatorModal}
          setModalIsVisible={setEditOperatorModal}
          operator={operator}
        />
      </Suspense>
    </>
  );
}

export default OperatorDetail;
