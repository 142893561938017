import {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  CSSProperties,
  ReactElement,
} from 'react';

import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import { IoMdArrowDropdown } from 'react-icons/io';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: CSSProperties;
  onFocus?: () => void;
  onBlur?: () => void;
  prefix?: string;
  placeholder?: string;
}

function Input({
  name,
  containerStyle = {},
  onFocus,
  onBlur,
  prefix,
  placeholder,
  ...rest
}: InputProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null); // HTMLInputElement - vai dar ao inputRef as propriedades de um input

  const [isFocused, setIsFocused] = useState(false); // Se esta com foco
  const [isFilled, setIsFilled] = useState(false); // Se esta preenchido

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    onFocus && onFocus();
  }, [onFocus]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    onBlur && onBlur();

    // Verifica se o inputRef tem um valor/value. Se tiver preenchido = true. Se tiver vazio = false. !! Tranforma o value em booleano.
    setIsFilled(!!inputRef.current?.value); // inputRef pega o valor direto do Input. document.querySelector('input') e etc.
  }, [onBlur]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        <p>{prefix}</p>
        <input
          onFocus={handleInputFocus} // Receber o foco
          onBlur={handleInputBlur} // Perder o foco
          defaultValue={defaultValue}
          ref={inputRef}
          placeholder={prefix ? '' : placeholder}
          {...rest}
        />

        <IoMdArrowDropdown size={20} />

        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </>
  );
}

export default Input;
