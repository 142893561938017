import styled from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3.2rem ${({ theme }) => theme.spacings.large} 6.4rem
    ${({ theme }) => theme.spacings.large};

  @media (max-width: ${breakpoints.medium}) {
    padding: 3.2rem 1.6rem;
  }
`;

export const Title = styled.h1`
  margin-bottom: 2.4rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 400;

  @media (max-width: ${breakpoints.medium}) {
    text-align: center;
  }
`;

export const SellerInfo = styled.section`
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  display: flex;
  gap: 2.4rem;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SellerInfoImgBox = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.colors.primary};
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SellerInfoImg = styled.img`
  max-width: 80px;
  max-height: 80px;
  border-radius: 50%;
`;

export const SellerInfoData = styled.div`
  max-width: 45rem;
  width: 100%;
`;

export const SellerInfoDataHeader = styled.div`
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 444px;
    background: ${({ theme }) => theme.colors.primary};
    margin: 1.6rem 0;

    @media (max-width: ${breakpoints.medium}) {
      width: 100%;
    }
  }
`;

export const SellerInfoDataList = styled.ul`
  display: flex;
  justify-content: center;
  column-gap: 2.4rem;
  row-gap: 1.6rem;

  list-style: none;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SellerInfoDataItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const SellerInfoDataTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 800;
`;

export const SellerInfoDataValue = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 400;
`;

export const SellerInfoDataName = styled.h2`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

export const SellerDetailWrapper = styled.section`
  width: 100%;
  padding: 3.2rem;
  border-radius: 7px;
  border: 1px solid rgba(70, 70, 70, 0.1);
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: ${breakpoints.medium}) {
    padding: 4rem 1.6rem;
  }
`;

export const SellerDetailDataWrapper = styled.section`
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 6.4rem;
  row-gap: 3.2rem;
  margin-bottom: 6.4rem;

  @media (max-width: ${breakpoints.medium}) {
    grid-template-columns: 1fr;
  }
`;

export const SellerDetailTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 3.2rem;

  @media (max-width: ${breakpoints.medium}) {
    text-align: center;
  }
`;

export const SellerDetailData = styled.section``;

export const SellerDetailExtract = styled.section`
  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    align-items: center;

    button {
      display: block;
      margin: 0 auto;
    }
  }
`;

export const SellerDetailDataItem = styled.div`
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #dcdcdc;
    margin: 1.6rem 0;
  }

  &:last-child:after {
    display: none;
  }
`;

export const SellerDetailDataItemKey = styled.h3`
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
  opacity: 0.5;
`;

export const SellerDetailDataItemValue = styled.p`
  font-size: 1.4rem;
`;

export const SellerServiceHistoryWrapper = styled.section``;

export const SellerDetailFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;
