import { AxiosPromise } from 'axios';

import api from './api';

import { IService } from '~/models/Common';
import { IOperator } from '~/models/Operator';
import { ILeadType } from '~/models/Lead';

export default class SeedsService {
  static getOperators(): AxiosPromise<IOperator[]> {
    return api.get('/operators');
  }

  static getServices(): AxiosPromise<IService[]> {
    return api.get('/services');
  }

  static getLeadTypes(): AxiosPromise<ILeadType[]> {
    return api.get('/lead-type');
  }
}
