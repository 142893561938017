import { useEffect } from 'react';
import { ReactElement, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { LogoColored, OrangeBaloon, WhiteBaloon } from '~/assets/icons';
import { Footer, StarRating, Button } from '~/components';
import { LeadService } from '~/services';
import { useQuery } from '~/utils/functions';

import * as S from './styles';

function RatingLead(): ReactElement {
  const [rating, setRating] = useState(0);
  const [finish, setFinish] = useState(false);

  const userId = useQuery().get('u');
  const leadId = useQuery().get('l');
  const router = useHistory();

  if (userId === null || leadId === null) {
    router.push('/');
    toast.error('Id do usuário ou do lead inválido.');
  }

  const handleSubmitRating = useCallback(async () => {
    try {
      await LeadService.putRatingLead(
        userId as string,
        leadId as string,
        rating,
      );

      setFinish(true);
    } catch (error) {
      toast.error('Não foi possível avaliar o atendimento.');
    }
  }, [leadId, rating, userId]);

  useEffect(() => {
    if (finish) {
      setTimeout(() => {
        router.push('/');
      }, 2000);
    }
  }, [finish, router]);

  return (
    <>
      <S.Content>
        <S.Header>
          <img src={LogoColored} alt="Logo ClientCom" />
        </S.Header>
      </S.Content>

      <S.Container id="formdiv">
        <ToastContainer />
        <S.BgImage>
          <img src={OrangeBaloon} alt="Background Balão" />
        </S.BgImage>
        <S.OrangeBg>
          <S.InputWrapper>
            <S.OrangeWrapper>
              <img src={WhiteBaloon} alt="Ícone balão" />
              <h1>Avalie o atendimento!</h1>
            </S.OrangeWrapper>
            <S.FormCard onSubmit={handleSubmitRating}>
              {!finish ? (
                <>
                  <S.InputWrapper>
                    <S.Label>Avaliação</S.Label>
                    <StarRating
                      name="size-large"
                      precision={0.5}
                      size="large"
                      value={rating}
                      onChange={(event, newValue) => {
                        setRating(newValue ?? 0);
                      }}
                    />
                  </S.InputWrapper>
                  <Button
                    type="submit"
                    containerStyle={{ maxWidth: 200 }}
                    textStyle={{ fontWeight: 400 }}
                  >
                    Concluir
                  </Button>
                </>
              ) : (
                <>
                  <S.ThanksMessage>
                    Obrigado por deixar sua avaliação!
                  </S.ThanksMessage>
                  <p>Você será redirecionado para a página inicial...</p>
                </>
              )}
            </S.FormCard>
          </S.InputWrapper>
        </S.OrangeBg>
      </S.Container>
      <Footer />
    </>
  );
}

export default RatingLead;
