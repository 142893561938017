import styled from 'styled-components';

export const DateWrapper = styled.div`
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    margin-right: 2rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    div:first-child {
      margin-right: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const AddressInputs = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    max-width: 250px;
  }
`;
