import { ReactElement } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { Admin } from '~/pages';

function AdminRoutes(): ReactElement {
  const baseRoute = '/admin';

  return (
    <Admin.Dashboard>
      <Redirect path={`${baseRoute}`} exact to={`${baseRoute}/leads`} />
      <Route path={`${baseRoute}/leads`} exact component={Admin.Leads} />
      <Route
        path={`${baseRoute}/leads/:id`}
        exact
        component={Admin.LeadDetail}
      />
      <Route path={`${baseRoute}/sellers`} exact component={Admin.Sellers} />
      <Route
        path={`${baseRoute}/sellers/:id`}
        exact
        component={Admin.SellerDetail}
      />
      <Route
        path={`${baseRoute}/operators`}
        exact
        component={Admin.Operators}
      />
      <Route
        path={`${baseRoute}/operators/:id`}
        exact
        component={Admin.OperatorDetail}
      />
      <Route path={`${baseRoute}/reports`} exact component={Admin.Reports} />
      <Route path={`${baseRoute}/push`} exact component={Admin.Push} />
    </Admin.Dashboard>
  );
}

export default AdminRoutes;
