import {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useMemo,
  ChangeEvent,
  useRef,
} from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSProperties } from 'styled-components';

import { ArrowRightRed, Order } from '~/assets/icons';
import { InputAdmin, Table, Pagination } from '~/components';
import {
  ILeadsPageOptionsRequest,
  IPagination,
  LeadsSortBy,
} from '~/models/Common';
import { ILead, LeadStatus } from '~/models/Lead';
import { AdminService } from '~/services';
import {
  handleFormatDate,
  handleFormatHourFromDate,
  handleFormatPhoneNumber,
} from '~/utils/functions';

import * as S from './styles';

function Leads(): ReactElement {
  const [leads, setLeads] = useState<IPagination<ILead[]>>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: '0',
  });
  const [searchFilter, setSearchFilter] = useState('');
  const [leadsStatusFilter, setLeadsStatusFilter] =
    useState<LeadStatus>('Finished');
  const [sortBy, setSortBy] = useState<LeadsSortBy>('date');
  const [pageOptions, setPageOptions] = useState<ILeadsPageOptionsRequest>({
    page: 1,
    name: '',
    sortBy: 'date',
  });
  const router = useHistory();
  const [direction, setDirection] = useState<string>('asc');

  const handleDirection = (): void => {
    setDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };

  const goToDetailPageButtonStyle = useRef<CSSProperties>({
    width: 9,
    height: 19,
  });

  const handleGetLeads = useCallback(async () => {
    try {
      const response = await AdminService.getAllLeads({
        ...pageOptions,
        name: searchFilter,
        status: leadsStatusFilter,
        sortBy,
        direction,
      });

      setLeads(response.data);
    } catch (error) {
      toast.error('Não foi possível carregar os leads.');
    }
  }, [leadsStatusFilter, pageOptions, searchFilter, sortBy, direction]);

  const handleGoToLeadPage = useCallback(
    (leadId: string) => {
      router.push(`/admin/leads/${leadId}`);
    },
    [router],
  );

  const handleSwapLeadsStatus = useCallback(
    (status: LeadStatus) => {
      setPageOptions({ ...pageOptions, page: 1 });
      setLeadsStatusFilter(status);
    },
    [pageOptions],
  );

  const handleChangeSearchFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchFilter(e.target.value);
    },
    [],
  );

  const DateCountryOffset = (currentLead: ILead): string => {
    const dateOffet = new Date(currentLead.created_at);
    dateOffet.setHours(
      dateOffet.getHours() - dateOffet.getTimezoneOffset() / 60,
    );
    return dateOffet.toISOString();
  };

  useEffect(() => {
    handleGetLeads();
  }, [
    handleGetLeads,
    pageOptions,
    leadsStatusFilter,
    searchFilter,
    sortBy,
    direction,
  ]);

  const TableLeadsMemo = useMemo(
    () =>
      leadsStatusFilter === 'Finished' ? (
        <Table
          key={0}
          noItems={leads?.data?.length === 0}
          tableHeadChildren={() => (
            <>
              <th
                onClick={() => {
                  setSortBy('date');
                  handleDirection();
                }}
              >
                Data/Hora{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortBy('name');
                  handleDirection();
                }}
              >
                Nome{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortBy('seller');
                  handleDirection();
                }}
              >
                Vendedor{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th> </th>
            </>
          )}
        >
          {leads.data?.map((lead) => (
            <tr key={lead.id} onClick={() => handleGoToLeadPage(lead.id)}>
              <td>{handleFormatDate(lead.created_at)}</td>
              <td>{lead.user ? lead.user.name : '-'}</td>
              <td>{lead.seller ? lead.seller.user?.name : '-'}</td>
              <td>
                <img
                  src={ArrowRightRed}
                  alt="Ir para a página do lead"
                  style={{ width: 9, height: 19 }}
                />
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <Table
          key={1}
          noItems={leads?.data?.length === 0}
          tableHeadChildren={() => (
            <>
              <th
                onClick={() => {
                  setSortBy('date');
                  handleDirection();
                }}
              >
                Data/Hora{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortBy('name');
                  handleDirection();
                }}
              >
                Nome{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortBy('phone');
                  handleDirection();
                }}
              >
                Telefone{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th> </th>
            </>
          )}
        >
          {leads?.data?.map((lead) => (
            <tr key={lead.id} onClick={() => handleGoToLeadPage(lead.id)}>
              <td>
                {handleFormatDate(lead.created_at)} -{' '}
                {handleFormatHourFromDate(DateCountryOffset(lead))}
              </td>
              <td>{lead.user.name ?? '-'}</td>
              <td>{handleFormatPhoneNumber(lead.user.phone)}</td>
              <td>
                <img
                  src={ArrowRightRed}
                  alt="Ir para a página do lead"
                  style={goToDetailPageButtonStyle.current}
                />
              </td>
            </tr>
          ))}
        </Table>
      ),
    [handleGoToLeadPage, leads, leadsStatusFilter],
  );

  return (
    <S.Container>
      <S.Title>Leads</S.Title>
      <S.TableWrapper>
        <S.TableHeader>
          <S.TableWrapperButton
            onClick={() => handleSwapLeadsStatus('Finished')}
            isInactive={leadsStatusFilter === 'Pending'}
          >
            Todos os leads
          </S.TableWrapperButton>
          <S.TableWrapperButton
            onClick={() => handleSwapLeadsStatus('Pending')}
            isInactive={leadsStatusFilter === 'Finished'}
          >
            Leads não atendidos
          </S.TableWrapperButton>
          <InputAdmin
            name="search"
            value={searchFilter}
            onChange={handleChangeSearchFilter}
          />
        </S.TableHeader>
        {TableLeadsMemo}
        <S.TableFooter>
          <Pagination
            pageCount={leads?.lastPage ?? 0}
            pageRangeDisplayed={pageOptions.page}
            {...{ setPageOptions }}
          />
        </S.TableFooter>
      </S.TableWrapper>
    </S.Container>
  );
}

export default Leads;
