import styled from 'styled-components';

export const Card = styled.div`
  min-width: 50vw;
  padding: 4rem;
  background-color: #fff;
  border-radius: 1.5rem;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 15px 17px #468ee526;
`;

export const Text = styled.p`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: 0.68px;
  color: #394761;
  max-width: 50rem;
  line-height: 30px;
`;

export const ModalImage = styled.img`
  width: 13rem;
  height: auto;
`;
