import {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  lazy,
  Suspense,
} from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSProperties } from 'styled-components';

import { Table, Button, Loading, StarRating } from '~/components';
import { ISeller } from '~/models/Seller';
import { AdminService } from '~/services';
import {
  handleFormatCEP,
  handleFormatDate,
  handleFormatPhoneNumber,
} from '~/utils/functions';

import * as S from './styles';

// lazy components
const ChangeBalanceModal = lazy(() => import('./ChangeBalanceModal'));

interface ISellerDetailParams {
  id: string;
}

function SellerDetail(): ReactElement {
  const [seller, setSeller] = useState<ISeller>();
  const [balanceModalStatus, setBalanceModalStatus] = useState<boolean>(false);
  const router = useHistory();
  const { id } = useParams<ISellerDetailParams>();

  const tableHeadStyle = useRef<CSSProperties>({
    backgroundColor: '#F0F0F0',
    color: '#000',
    fontWeight: 'bold',
  });

  const tableContainerStyle = useRef<CSSProperties>({
    border: '1px solid #BCBCBC',
    marginBottom: '3.2rem',
  });

  const extractTableWrapperStyle = useRef<CSSProperties>({
    height: '209px',
    overflowY: 'scroll',
    marginBottom: '1.6rem',
  });

  const creditsSpanStyle = useMemo(
    () => ({
      color: seller?.credits && seller?.credits > 0 ? '#00875A' : '#DD5C32',
    }),
    [seller],
  );

  const buttonsContainerStyle = useRef<CSSProperties>({
    borderRadius: '10px',
    maxWidth: '214px',
  });

  const handleOpenBalanceModal = useCallback(() => {
    setBalanceModalStatus(true);
  }, []);

  const handleGoBack = useCallback(() => {
    router.goBack();
  }, [router]);

  const handleGetSellerById = useCallback(async () => {
    try {
      const response = await AdminService.getSeller(id);

      setSeller(response.data);
    } catch (error) {
      toast.error(`${error}`);
    }
  }, [id]);

  const handleNavigateToLeadDetail = useCallback(
    (leadId: string) => {
      router.push(`/admin/leads/${leadId}`);
    },
    [router],
  );

  useEffect(() => {
    handleGetSellerById();
  }, [handleGetSellerById, balanceModalStatus]);

  const SellerOperatorsMemo = useMemo(
    () =>
      seller?.operators && seller?.operators.length > 0
        ? seller?.operators.map(
            (operator, index) =>
              `${operator.name} ${
                index === seller.operators.length - 1 ? '' : '-'
              }`,
          )
        : '-',
    [seller],
  );

  const SellerExtractTableMemo = useMemo(
    () =>
      seller?.extracts.map((extractItem) => (
        <tr key={extractItem.id}>
          <td>{handleFormatDate(extractItem.created_at)}</td>
          <td>{extractItem.description ?? '-'}</td>
          <td style={{ color: extractItem.isDebit ? '#DD5C32' : '#00875A' }}>
            {extractItem.isDebit && extractItem.value !== 0 ? '-' : ''}
            {extractItem.value}
          </td>
        </tr>
      )),
    [seller],
  );

  const SellerLeadsHistoryMemo = useMemo(
    () =>
      seller?.leads &&
      seller?.leads.length > 0 && (
        <S.SellerServiceHistoryWrapper>
          <S.SellerDetailTitle>
            Histórico de atendimento do lead
          </S.SellerDetailTitle>
          <Table
            wrapperStyle={extractTableWrapperStyle.current}
            headStyle={tableHeadStyle.current}
            containerStyle={tableContainerStyle.current}
            tableHeadChildren={() => (
              <>
                <th>Data/Hora</th>
                <th>Nome do lead</th>
                <th>Tipo de atendimento</th>
                <th>Avaliação</th>
              </>
            )}
          >
            {seller.leads.map((lead) => (
              <tr
                key={lead.id}
                onClick={() => handleNavigateToLeadDetail(lead.id)}
              >
                <td>{handleFormatDate(lead.created_at)}</td>
                <td>{lead.user.name ?? '-'}</td>
                <td>{lead.lead_type?.name ?? '-'}</td>
                <td>
                  {lead.rating ? (
                    <StarRating
                      name="readonly"
                      value={parseInt(lead.rating, 10)}
                      precision={0.5}
                      readOnly
                    />
                  ) : (
                    `-`
                  )}
                </td>
              </tr>
            ))}
          </Table>
        </S.SellerServiceHistoryWrapper>
      ),
    [handleNavigateToLeadDetail, seller],
  );

  return (
    <>
      <S.Container>
        <S.SellerInfo>
          <S.SellerInfoImgBox>
            <S.SellerInfoImg
              src={seller?.user?.image_url ? seller.user.image_url : ''}
              alt={seller?.user?.name || '-'}
              title={seller?.user?.name || '-'}
            />
          </S.SellerInfoImgBox>
          <S.SellerInfoData>
            <S.SellerInfoDataHeader>
              <S.SellerInfoDataList>
                <S.SellerInfoDataItem>
                  <S.SellerInfoDataTitle>Nome: </S.SellerInfoDataTitle>
                  <S.SellerInfoDataValue>
                    {seller?.user?.name}
                  </S.SellerInfoDataValue>
                </S.SellerInfoDataItem>
              </S.SellerInfoDataList>
            </S.SellerInfoDataHeader>
            <S.SellerInfoDataTitle as="h1" style={{ textAlign: 'center' }}>
              Vendedor
            </S.SellerInfoDataTitle>
          </S.SellerInfoData>
        </S.SellerInfo>
        <S.SellerDetailWrapper>
          <S.SellerDetailDataWrapper>
            <S.SellerDetailData>
              <S.SellerDetailTitle>Dados do vendedor</S.SellerDetailTitle>
              <S.SellerDetailDataItem>
                <S.SellerDetailDataItemKey>Nome</S.SellerDetailDataItemKey>
                <S.SellerDetailDataItemValue>
                  {seller?.user?.name ?? '-'}
                </S.SellerDetailDataItemValue>
              </S.SellerDetailDataItem>
              <S.SellerDetailDataItem>
                <S.SellerDetailDataItemKey>E-mail</S.SellerDetailDataItemKey>
                <S.SellerDetailDataItemValue>
                  {seller?.user?.email ?? '-'}
                </S.SellerDetailDataItemValue>
              </S.SellerDetailDataItem>
              <S.SellerDetailDataItem>
                <S.SellerDetailDataItemKey>Telefone</S.SellerDetailDataItemKey>
                <S.SellerDetailDataItemValue>
                  {seller?.user?.phone
                    ? handleFormatPhoneNumber(seller?.user?.phone)
                    : '-'}
                </S.SellerDetailDataItemValue>
              </S.SellerDetailDataItem>
              <S.SellerDetailDataItem>
                <S.SellerDetailDataItemKey>
                  Operadoras
                </S.SellerDetailDataItemKey>
                <S.SellerDetailDataItemValue>
                  {SellerOperatorsMemo}
                </S.SellerDetailDataItemValue>
              </S.SellerDetailDataItem>
              <S.SellerDetailDataItem>
                <S.SellerDetailDataItemKey>CEP</S.SellerDetailDataItemKey>
                <S.SellerDetailDataItemValue>
                  {seller?.address.zip_code
                    ? handleFormatCEP(seller?.address.zip_code)
                    : '-'}
                </S.SellerDetailDataItemValue>
              </S.SellerDetailDataItem>
            </S.SellerDetailData>
            <S.SellerDetailExtract>
              <S.SellerDetailTitle>Extrato</S.SellerDetailTitle>
              <Table
                wrapperStyle={extractTableWrapperStyle.current}
                headStyle={tableHeadStyle.current}
                containerStyle={tableContainerStyle.current}
                tableHeadChildren={() => (
                  <>
                    <th>Data</th>
                    <th>Tipo de movimentação</th>
                    <th>Crédito (em R$)</th>
                  </>
                )}
              >
                {SellerExtractTableMemo}
              </Table>
              <S.SellerDetailTitle>
                Saldo atual do vendedor:{' '}
                <span style={creditsSpanStyle}>
                  {seller?.credits ? `R$ ${seller?.credits}` : '-'}
                </span>
              </S.SellerDetailTitle>
              <Button
                style={buttonsContainerStyle.current}
                onClick={handleOpenBalanceModal}
              >
                Alterar saldo
              </Button>
            </S.SellerDetailExtract>
          </S.SellerDetailDataWrapper>
          {SellerLeadsHistoryMemo}
          <S.SellerDetailFooter>
            <Button
              onClick={handleGoBack}
              containerStyle={buttonsContainerStyle.current}
            >
              Voltar
            </Button>
          </S.SellerDetailFooter>
        </S.SellerDetailWrapper>
      </S.Container>

      <Suspense fallback={<Loading loading />}>
        <ChangeBalanceModal
          modalIsVisible={balanceModalStatus}
          setModalIsVisible={setBalanceModalStatus}
          seller={seller}
        />
      </Suspense>
    </>
  );
}

export default SellerDetail;
