import { ReactElement, useCallback, Dispatch, SetStateAction } from 'react';

import * as S from './styles';

interface IMenuMobileToggleProps {
  menuVisible?: boolean;
  setMenuVisible?: Dispatch<SetStateAction<boolean>>;
}

function MenuMobileToggle({
  menuVisible,
  setMenuVisible,
}: IMenuMobileToggleProps): ReactElement {
  const handleToggleMenuActive = useCallback(() => {
    setMenuVisible && setMenuVisible(!menuVisible);
  }, [menuVisible, setMenuVisible]);

  return (
    <S.MenuMobileToggleWrapper
      type="button"
      className="menu-mobile-toggle"
      onClick={handleToggleMenuActive}
      data-mobile="toggle"
      isActive={menuVisible}
      title="Menu"
    >
      <span />
    </S.MenuMobileToggleWrapper>
  );
}

export default MenuMobileToggle;
