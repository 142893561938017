import {
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  lazy,
  Suspense,
} from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSProperties } from 'styled-components';

import { ArrowRightRed, Order } from '~/assets/icons';
import { Table, Button, Pagination, Loading } from '~/components';
import { PageOptionsRequest } from '~/components/Pagination';
import {
  IPagination,
  OperatorsSortBy,
  SuggestOperatorsSortBy,
} from '~/models/Common';
import { IOperator, ISuggestedOperator } from '~/models/Operator';
import { AdminService } from '~/services';
import { handleFormatDate } from '~/utils/functions';

import * as S from './styles';

// lazy components
const SaveOperatorModal = lazy(() => import('./SaveOperatorModal'));

type OperatorStatus = 'saved' | 'suggested';

function Operators(): ReactElement {
  const [operators, setOperators] = useState<IPagination<IOperator[]>>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: '0',
  });
  const [suggestedOperators, setSuggestedOperators] = useState<
    IPagination<ISuggestedOperator[]>
  >({ data: [], lastPage: 0, page: 1, perPage: 1, total: '0' });
  const [sortByOperators, setSortByOperators] =
    useState<OperatorsSortBy>('name');
  const [sortBySuggestOperators, setSortBySuggestOperators] =
    useState<SuggestOperatorsSortBy>('name');
  const [operatorsStatusFilter, setOperatorsStatusFilter] =
    useState<OperatorStatus>('saved');
  const [saveOperatorModal, setSaveOperatorModal] = useState<boolean>(false);
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const router = useHistory();

  const [direction, setDirection] = useState<string>('asc');

  const handleDirection = (): void => {
    setDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };

  const goToDetailPageButtonStyle = useRef<CSSProperties>({
    width: 9,
    height: 19,
  });

  const newOperatorButtonStyle = useRef<CSSProperties>({
    borderRadius: '10px',
    maxWidth: '214px',
  });

  const handleSwapOperatorStatus = useCallback((status: OperatorStatus) => {
    setOperatorsStatusFilter(status);
  }, []);

  const handleOpenSaveOperatorModal = useCallback(() => {
    setSaveOperatorModal(true);
  }, []);

  const handleGoToOperatorPage = useCallback(
    (operatorId: string) => {
      router.push(`/admin/operators/${operatorId}`);
    },
    [router],
  );

  const handleGetOperators = useCallback(async () => {
    try {
      const response = await AdminService.getOperators({
        ...pageOptions,
        sortBy: sortByOperators,
        direction,
      });

      setOperators(response.data);
    } catch (error) {
      toast.error(`Não foi possível listar as operadoras.`);
    }
  }, [pageOptions, sortByOperators, direction]);

  const handleGetSuggestedOperators = useCallback(async () => {
    try {
      const response = await AdminService.getSuggestOperators({
        ...pageOptions,
        sortBy: sortBySuggestOperators,
      });

      setSuggestedOperators(response.data);
    } catch (error) {
      toast.error(`Não foi possível listar as operadoras sugeridas.`);
    }
  }, [pageOptions, sortBySuggestOperators]);

  useEffect(() => {
    if (operatorsStatusFilter === 'suggested') {
      handleGetSuggestedOperators();
    } else {
      handleGetOperators();
    }
  }, [
    pageOptions,
    saveOperatorModal,
    handleGetSuggestedOperators,
    operatorsStatusFilter,
    handleGetOperators,
    sortBySuggestOperators,
    sortByOperators,
    direction,
  ]);

  const OperatorsTableMemo = useMemo(
    () =>
      operatorsStatusFilter === 'saved' ? (
        <Table
          key={0}
          noItems={operators?.data?.length === 0}
          tableHeadChildren={() => (
            <>
              <th
                onClick={() => {
                  setSortByOperators('name');
                  handleDirection();
                }}
              >
                Nome da operadora
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByOperators('sellers_qnt');
                  handleDirection();
                }}
              >
                Vendedores cadastrados{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortByOperators('status');
                  handleDirection();
                }}
              >
                Status{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th> </th>
            </>
          )}
        >
          {operators?.data?.map((operator) => (
            <tr
              key={operator.id}
              onClick={() => handleGoToOperatorPage(operator.id)}
            >
              <td>{operator.name}</td>
              <td>{operator.sellers_qnt}</td>
              <td>{operator.status ? 'Ativo' : 'Inativo'}</td>
              <td>
                <img
                  src={ArrowRightRed}
                  alt="Ir"
                  style={goToDetailPageButtonStyle.current}
                />
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <Table
          key={1}
          noItems={suggestedOperators?.data?.length === 0}
          tableHeadChildren={() => (
            <>
              <th
                onClick={() => {
                  setSortBySuggestOperators('name');
                  handleDirection();
                }}
              >
                Nome da operadora{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortBySuggestOperators('date');
                  handleDirection();
                }}
              >
                Data da sugestão{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
              <th
                onClick={() => {
                  setSortBySuggestOperators('consumer_name');
                  handleDirection();
                }}
              >
                Nome do cliente{' '}
                <img
                  src={Order}
                  alt="Nome completo"
                  style={{ marginLeft: '10px' }}
                />
              </th>
            </>
          )}
        >
          {suggestedOperators?.data?.map((suggestedOperator) => (
            <tr key={suggestedOperator.id}>
              <td>{suggestedOperator.name}</td>
              <td>{handleFormatDate(suggestedOperator.created_at)}</td>
              <td>{suggestedOperator.suggest_by.name}</td>
            </tr>
          ))}
        </Table>
      ),
    [
      handleGoToOperatorPage,
      operators,
      operatorsStatusFilter,
      suggestedOperators,
    ],
  );

  return (
    <>
      <S.Container>
        <S.Title>Operadoras</S.Title>
        <S.TableWrapper>
          <S.TableHeader>
            <S.TableWrapperButton
              onClick={() => handleSwapOperatorStatus('saved')}
              isInactive={operatorsStatusFilter === 'suggested'}
            >
              Operadoras cadastradas
            </S.TableWrapperButton>
            <S.TableWrapperButton
              onClick={() => handleSwapOperatorStatus('suggested')}
              isInactive={operatorsStatusFilter === 'saved'}
            >
              Operadoras sugeridas
            </S.TableWrapperButton>
          </S.TableHeader>
          {OperatorsTableMemo}
          <S.TableFooter>
            <Button
              onClick={handleOpenSaveOperatorModal}
              containerStyle={newOperatorButtonStyle.current}
            >
              Nova Operadora
            </Button>
            <Pagination
              pageCount={
                operatorsStatusFilter === 'saved'
                  ? operators.lastPage ?? 0
                  : suggestedOperators.lastPage ?? 0
              }
              pageRangeDisplayed={pageOptions.page}
              {...{ setPageOptions }}
            />
          </S.TableFooter>
        </S.TableWrapper>
      </S.Container>

      <Suspense fallback={<Loading loading />}>
        <SaveOperatorModal
          modalIsVisible={saveOperatorModal}
          setModalIsVisible={setSaveOperatorModal}
        />
      </Suspense>
    </>
  );
}

export default Operators;
