import { ReactElement, ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

import * as S from './styles';

interface TableProps {
  children?: ReactNode;
  noItems?: boolean;
  noItemsMessage?: string;
  headStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  tableHeadChildren?: () => JSX.Element;
}

function Table({
  tableHeadChildren: TableHeadChildren,
  children,
  noItems,
  noItemsMessage,
  headStyle,
  containerStyle,
  wrapperStyle,
  bodyStyle,
}: TableProps): ReactElement {
  if (noItems)
    return (
      <S.TableNoItems>
        {noItemsMessage ?? 'Nenhum resultado encontrado.'}
      </S.TableNoItems>
    );
  return (
    <S.TableWrapper style={wrapperStyle}>
      <S.Table style={containerStyle}>
        <S.TableHead style={headStyle}>
          <tr>{TableHeadChildren && <TableHeadChildren />}</tr>
        </S.TableHead>
        <S.TableBody style={bodyStyle}>{children}</S.TableBody>
      </S.Table>
    </S.TableWrapper>
  );
}

export default Table;
