const pt_BR = {
  Home: {
    title: 'Acesse sua conta',
    button: 'ENTRAR',
    email_placeholder: 'Email',
    password_placeholder: 'Senha',
  },
};

export default pt_BR;
