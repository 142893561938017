import { Loader } from './styles';

interface LoadingProps {
  loading: boolean;
  percent?: number;
  size?: number;
}

const Loading: React.FC<LoadingProps> = ({ loading, percent, size }) => (
  <>
    {loading && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
        <Loader color="#fff" loading={loading} size={size || 120} />
        <div style={{ color: '#fff', fontSize: 14, textAlign: 'center' }}>
          {percent && percent > 0 ? `${percent?.toFixed(0)}%` : ''}
        </div>
      </div>
    )}
  </>
);

export default Loading;
