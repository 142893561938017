import React, { useState } from 'react';
import { FormHandles } from '@unform/core';

import * as S from '../styles';
import * as C from './styles';
import ModalTerms from './ModalTerms';
import {
  Button,
  Input,
  InputRadio,
  InputMask,
  InputCheckbox,
} from '~/components';
import { IUserContact } from '~/models/User';
import { light } from '~/styles/themes';

interface ContactProps {
  handleSubmit: (data: IUserContact) => void;
  setPreferencesFilled: (state: boolean) => void;
  formRef: React.RefObject<FormHandles>;
}

const Contact: React.FC<ContactProps> = ({
  handleSubmit,
  setPreferencesFilled,
  formRef,
}) => {
  const whatsappOptions = [
    { id: 1, label: 'Sim', value: true },
    { id: 2, label: 'Não', value: false },
  ];

  const [modalTerms, setModalTerms] = useState(false);
  function gtag_report_conversion(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    gtag('event', 'conversion', {
      send_to: 'AW-10801118213/7fWRCP_UsYIDEIX4r54o',
    });
    return false;
  }

  return (
    <>
      <S.FormCard ref={formRef} onSubmit={handleSubmit}>
        <C.Title>Pronto! Já registramos suas preferências.</C.Title>
        <C.Subtitle>
          Agora preencha seus dados para que possamos entrar em contato.
        </C.Subtitle>
        <S.InputWrapper>
          <S.Label>Nome</S.Label>
          <Input name="name" />
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>Email</S.Label>
          <Input name="email" type="email" />
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>Celular</S.Label>
          <InputMask name="phone" type="tel" mask="(99) 99999-9999" />
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>Deseja ser contatado pelo WhatsApp?</S.Label>
          <InputRadio name="contact_by_whatsapp" options={whatsappOptions} />
        </S.InputWrapper>
        <C.TermsWrapper>
          <InputCheckbox
            name="accept_terms"
            options={[
              {
                id: 1,
                value: true,
                label: '',
              },
            ]}
          />
          <C.Terms
            type="button"
            onClick={() => {
              setModalTerms(true);
            }}
          >
            Declaro que li e aceito os termos de uso e privacidade da plataforma
            ClientCom*
          </C.Terms>
        </C.TermsWrapper>
        <C.ButtonsWrapper>
          <Button
            containerStyle={{
              maxWidth: 200,
              border: `1px solid ${light.colors.primary}`,
              boxShadow: 'none',
            }}
            bgColor="#fff"
            textColor={light.colors.primary}
            textStyle={{ fontWeight: 400 }}
            onClick={() => {
              setPreferencesFilled(false);
            }}
          >
            Voltar
          </Button>
          <Button
            id="submit-form-finished"
            containerStyle={{ maxWidth: 200, boxShadow: 'none' }}
            textStyle={{ fontWeight: 400 }}
            onClick={() => {
              gtag_report_conversion();
              formRef.current?.submitForm();
            }}
          >
            Concluir
          </Button>
        </C.ButtonsWrapper>
      </S.FormCard>
      <ModalTerms modalTerms={modalTerms} setModalTerms={setModalTerms} />
    </>
  );
};

export default Contact;
