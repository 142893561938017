import styled from 'styled-components';

interface IImgDownloadApp {
  type: 'apple' | 'google';
}

export const Container = styled.div`
  background-color: #fff;
  padding: 50px 20px 50px 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;

  @media (max-width: 425px) {
    width: auto;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const ContainerText = styled.div`
  width: 40%;

  @media (min-width: 2561px) {
    width: 15%;
  }

  @media (max-width: 2491px) {
    width: 28%;
  }

  @media (max-width: 768px) {
    width: auto;
  }
`;

export const Title = styled.div`
  font-size: 4rem;
  font-family: Calibri;
  color: #2d2d2d;
  line-height: 4rem;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    font-size: 3.5rem;
  }
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

export const Description = styled.div`
  font-size: 2rem;
  font-family: Calibri;
  color: #2d2d2d;
  line-height: 2rem;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ContainerDownloadApp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
`;

export const ImgDownloadApp = styled.img<IImgDownloadApp>`
  margin-right: 20px;
  cursor: pointer;
  width: auto;
  height: ${({ type }) => (type === 'apple' ? 68 : 77)}px;

  @media (max-width: 1024px) {
    height: ${({ type }) => (type === 'apple' ? 45 : 51)}px;
  }
  @media (max-width: 768px) {
    height: ${({ type }) => (type === 'apple' ? 34 : 38.5)}px;
  }
`;

export const ImgWoman = styled.img`
  width: 450px;

  @media (max-width: 1024px) {
    width: 395px;
  }
  @media (max-width: 768px) {
    width: 237px;
  }
`;
