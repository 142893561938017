import React from 'react';
import { LogoBigCOrange, PosterVideoHome } from '~/assets/images';
import { VideoServices } from '~/assets/videos';

import * as S from './styles';
import { WhiteCoatDoctor } from '~/assets/images/health';

/* eslint-disable jsx-a11y/media-has-caption */

const HomeDescription: React.FC = () => (
  <S.BlackBg>
    <S.BlackBgImage>
      <img src={LogoBigCOrange} alt="ClientCom Logo" id="blackbglogo" />
    </S.BlackBgImage>
    <S.WomanDiv>
      {/* <video poster={PosterVideoHome} controls>
        <source src={VideoServices} type="video/mp4" />
        Your browser does not support HTML video.
      </video> */}
      <img src={WhiteCoatDoctor} alt="doutor com jaleco branco" />
    </S.WomanDiv>
    <S.TextWrapper>
      <S.WhiteTitle>
        Canal de Compras de <strong> Planos de Saúde para PF e PJ</strong>
      </S.WhiteTitle>

      <S.WhiteText>
        Agora você pode ter o atendimento que antes era{' '}
        <b>restrito às grandes contas.</b>
        <br />
        <br /> De forma <b>100% gratuita</b>, você solicita atendimento dos{' '}
        <b>melhores representantes comerciais </b> do Brasil, e terá{' '}
        <b>atendimento prioritário</b> de um vendedor da sua cidade em <br />
        <b>até 5 minutos</b>, acesso às melhores <b>ofertas</b>, consultoria
        para diagnóstico das suas necessidades e contato direto para dúvidas.
        <br />
        <br /> A <b>revolução do atendimento </b> agora está acessível a pessoa
        física e pessoa Juridica, é só preencher o formulário e conhecer a
        Clientcom.
      </S.WhiteText>
    </S.TextWrapper>
  </S.BlackBg>
);

export default HomeDescription;
