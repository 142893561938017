import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  border: 1px solid #c1c1c1;
  margin-bottom: 20px;
  background: #f3f3f366 0% 0% no-repeat padding-box;
  padding-right: 2rem;

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030 !important;
    `}

  /* Se o input estiver focado, mude a cor do icone e da borda. A cor do icone esta conectado com color */
  /* ${(props) =>
    props.isFocused &&
    css`
      color: green;
      border-color: #ffdf22;
    `} */

  /* Se o input estiver preenchido, mude a cor do icone. A cor do icone esta conectado com color */
  ${(props) =>
    props.isFilled &&
    css`
      /* border: 1px solid #dd5c32; */
    `}

  // Icon:
  >svg {
    color: #c1c1c1;

    ${(props) =>
      props.isFilled &&
      css`
        color: #dd5c32;
      `}
  }

  > input {
    flex: 1;
    width: 100%;
    background: #f9f9f9;
    border: 0;
    border-radius: 0.8rem;
    padding: 5px 20px;

    color: #394761;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.52px;

    ::placeholder {
      color: #39476180;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: 0.52px;
    }

    ::-webkit-calendar-picker-indicator {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23c1c1c1" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');

      ${(props) =>
        props.isFilled &&
        css`
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23dd5c32" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
        `}
    }

    /* &::placeholder {
      color: $props => props.placeholderColor;
    } */
  }
`;

export const Error = styled(Tooltip)`
  height: 2rem;
  margin-right: -0.5rem;
  margin-left: 0.5rem;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Label = styled.label`
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 0 0.6rem 1.2rem;
  user-select: none;
`;
