import { Dispatch, SetStateAction, ReactElement, useEffect } from 'react';

import * as S from './styles';
import { ModalText } from '../styles';
import { ModalBlack, Cronometer } from '~/components';

interface IFinishProps {
  modalIsVisible: boolean;
  setModalIsVisible: Dispatch<SetStateAction<boolean>>;
  sellerFound: boolean;
}

function Finish({
  modalIsVisible,
  setModalIsVisible,
  sellerFound,
}: IFinishProps): ReactElement {
  // useEffect(() => {
  //   window.ga('send', {
  //     hitType: 'event',
  //     eventCategory: 'lead',
  //     eventAction: 'submit',
  //     eventLabel: 'lead',
  //   });
  // }, []);

  return (
    <>
      <S.Card>
        <S.Text>
          {!sellerFound ? (
            <>
              <strong>Obrigada!</strong> Estamos encontrando o vendedor mais
              próximo de você e em alguns minutos ele entrará em contato.
            </>
          ) : (
            <>
              <strong>Vendedor encontrado!</strong> Aguarde o atendimento.
              Obrigado pela preferência.
            </>
          )}
        </S.Text>
      </S.Card>
      <ModalBlack
        modalVisible={modalIsVisible}
        setModalVisible={setModalIsVisible}
        containerStyle={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          justifyContent: 'center',
        }}
        backdropStyle={{ backgroundColor: '#1e1e1ee6' }}
        btnClose
      >
        <Cronometer durationInMinutes={5} setModalOpen={setModalIsVisible} />
        <ModalText style={{ fontWeight: 700, marginTop: 100 }}>
          Tudo certo!
        </ModalText>
        <ModalText>Seu vendedor entrará em contato!</ModalText>
      </ModalBlack>
    </>
  );
}

export default Finish;
