import React, { useEffect, useState } from 'react';

import { Loading } from '~/components';
import { msToTime } from '~/utils/functions';

import * as S from './styles';

interface CronometerProps {
  durationInMinutes: number;
  setModalOpen?: (state: boolean) => void;
}

const Cronometer: React.FC<CronometerProps> = ({
  durationInMinutes,
  setModalOpen,
}) => {
  const durationInMs = durationInMinutes * 60000;
  const [milliseconds, setMilliseconds] = useState(durationInMs);
  const [cron, setCron] = useState(msToTime(durationInMs));

  useEffect(() => {
    const timer = window.setInterval(
      // eslint-disable-next-line prefer-arrow-callback
      function ChangeCron(): void {
        if (milliseconds > 0) {
          setMilliseconds(milliseconds - 1000);
          setCron(msToTime(milliseconds));
        } else {
          clearInterval(timer);
          setModalOpen && setModalOpen(false);
        }
      },
      1000,
    );
    return () => {
      clearInterval(timer);
    };
  }, [milliseconds]);

  return (
    <S.Container>
      <S.Wrapper>
        <Loading loading size={150} />
        <S.Counter>{cron}</S.Counter>
      </S.Wrapper>
    </S.Container>
  );
};

export default Cronometer;
