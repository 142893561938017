import styled from 'styled-components';
import { Button } from '~/components';

export const GreyBg = styled.div`
  width: 100%;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;

  @media (max-width: 930px) {
    flex-direction: column-reverse;
    max-height: 80vh;
  }

  @media (max-width: 450px) {
    button {
      padding: 5px 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8rem;
  padding-top: 5rem;

  @media (max-width: 930px) {
    padding-left: 0;
    padding-top: 0;
    padding: 2rem;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 400px) {
    margin-top: -4rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 25rem;
    height: auto;
  }

  @media (max-width: 930px) {
    img {
      width: 10rem;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PageTitle = styled.h1`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 5rem;
  color: #2d2d2d;
  max-width: 600px;
  margin-top: 8rem;
  line-height: 70px;

  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 930px) {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: 2rem;
  }
`;

export const PageSubtitle = styled.p`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 2rem;
  color: #2d2d2d;
  max-width: 683px;
  margin-top: 3rem;

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
  }

  &.textSpan {
    margin-top: 2rem;
  }

  @media (max-width: 930px) {
    font-size: 1.5rem;
  }

  @media (max-width: 400px) {
    margin-top: 1.5rem;

    &.textSpan {
      margin-top: 1rem;
    }
  }
`;

export const CustomButton = styled(Button)`
  margin-top: 4.4rem;
  margin-bottom: 7rem;
  box-shadow: none;
  max-width: none;
  width: max-content;

  @media (max-width: 930px) {
    margin-top: 2rem;
    margin-bottom: 0rem;
    max-width: 250px;
    height: 3rem;

    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 470px) {
    max-width: 200px;
    padding: 5rem 3rem;
  }

  @media (max-width: 449px) {
    padding: 0.5rem 0.8rem !important;
  }
`;

export const ManDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  right: 0;
  bottom: -12rem;
  width: 110rem;
  height: 60rem;

  border-radius: 50% 0 0 50%;
  background-color: #eeebeb;

  &:hover {
    #backgroundlogo {
      transform: rotate(120deg);
      margin-top: -2rem;
    }
  }

  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  @media (max-width: 1120px) {
    bottom: -17rem;
  }

  /* @media (max-width: 1065px) {
    width: 110rem;
    height: 57rem;
    bottom: -22rem;
  } */

  @media (max-width: 930px) {
    top: 0;
    margin: 0 auto;
    height: 260px;
    width: 180px;
    max-width: 35rem;
    border-radius: 0 0 45% 45%;
    overflow: hidden;

    div {
    }
  }

  @media (max-width: 400px) {
    max-width: 30rem;
    top: -4rem;
    bottom: -80px;

    div {
    }
  }
`;

export const ManImage = styled.img`
  z-index: 2;
  position: absolute;
  bottom: 0;
  //left: -5px;
  //width: 55rem;
  //height: auto;
  height: 60rem;
  border-radius: 8px;

  @media (max-width: 930px) {
    width: 100%;
    height: 99%;
  }
`;

export const BackgroundImageLogo = styled.img`
  transition: 300ms ease all;
  position: absolute;
  transform: rotate(45deg);
  width: 100%;
  height: auto;
  margin-left: -8rem;
  margin-top: 2rem;
  z-index: 1;

  @media (max-width: 930px) {
    margin-left: -3rem;
    margin-bottom: 1rem;
  }
`;
