import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Home, Service, Admin, RatingLead, TermsOfUse } from '~/pages';
import AdminRoute from './types/admin.route';
import AdminRoutes from './admin.routes';

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/atendimento" exact component={Service} />
      <Route path="/rating-lead" exact component={RatingLead} />
      <Route
        path="/sobre/politicas-de-privacidade"
        exact
        component={TermsOfUse}
      />
      <Route path="/admin/signin" component={Admin.SignIn} />
      <AdminRoute isPrivate path="/admin" component={AdminRoutes} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
