import styled from 'styled-components';

interface ContainerProps {
  isChecked?: boolean;
}
export const Container = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  background: transparent;
  color: #394761;
  width: 100%;
  /* height: 100%; */
  /* border-bottom: 0.1rem solid ${({ theme }) => theme.colors.primary}; */
  padding-bottom: ${({ theme }) => theme.spacings.small};
  margin-bottom: ${({ theme }) => theme.spacings.small};
  cursor: pointer;
  transition: all 200ms ease-in-out;

  p {
    margin-left: 2.8rem;
    padding-top: 2.5px;
    font-size: 14px;
    letter-spacing: 0.28px;
    font-weight: 400;
  }
  svg {
    cursor: pointer;
    position: absolute;
    right: 3.2rem;
  }
  input {
    appearance: none;
  }
  span {
    border-radius: 50%;
    height: 2.1rem;
    width: 2.1rem;
    background: #f3f3f366;
    border: 0.1rem solid #c1c1c1;
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 2;
      background-color: #fff;
    }
  }
  span:after {
    content: '';
    height: 2.1rem;
    width: 2.1rem;
    background: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    transition: 300ms ease-in-out 0s;
  }

  input[type='radio']:checked ~ span:after {
    transform: translate(-50%, -50%) scale(1);
  }
`;
