import React from 'react';
import { useHistory } from 'react-router';

import * as S from './styles';
import { DoctorCellPhone } from '~/assets/images/health';

const HomeDescription: React.FC = () => {
  const history = useHistory();
  return (
    <S.BlackBg>
      <S.WomanDiv>
        <img src={DoctorCellPhone} alt="Homem com celular" />
      </S.WomanDiv>
      <S.TextWrapper>
        <S.Title>
          Com a ClientCom, você terá acesso aos melhores preços e{' '}
          <strong>planos de saúde </strong>
          através de <strong>especialistas.</strong>
        </S.Title>
        <S.Button
          onClick={() => {
            window.scrollTo(0, 0);
            history.push('/atendimento');
          }}
        >
          Ir para área do cliente
        </S.Button>
      </S.TextWrapper>
    </S.BlackBg>
  );
};

export default HomeDescription;
