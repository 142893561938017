import React from 'react';
import { GooglePlay, AppStore } from '~/assets/images';

import * as S from './styles';
import { Doctor } from '~/assets/images/health';

const HomeInformations: React.FC = () => (
  <S.Container>
    <S.ContainerText>
      <S.Title>
        Somos uma startup criada para conectar você, vendedor, ao cliente ideal.
      </S.Title>
      <S.Description>Aumente seus resultados e sua eficiência!</S.Description>
      <S.ContainerDownloadApp
        onClick={() =>
          window.open('https://apps.apple.com/br/app/clientcom/id1587176947')
        }
      >
        <S.ImgDownloadApp
          type="google"
          height={77}
          src={AppStore}
          alt="Download com Apple Store"
        />
        <S.ImgDownloadApp
          type="apple"
          onClick={() =>
            window.open(
              'https://play.google.com/store/apps/details?id=com.clicksoft.clientcom',
            )
          }
          height={68}
          src={GooglePlay}
          alt="Download com Play Store"
        />
      </S.ContainerDownloadApp>
    </S.ContainerText>
    <S.ImgWoman src={Doctor} alt="Doutora" />
  </S.Container>
);

export default HomeInformations;
