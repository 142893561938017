import { ReactElement, useRef, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CSSProperties } from 'styled-components';

import { LogoColored } from '~/assets/icons';
import { Input, Button } from '~/components';
import { useAuth } from '~/hooks/auth';
import { handleYupValidationError } from '~/utils/functions';
import { userSignInSchema } from '~/utils/schemas';
import { UserSignInDto } from '~/utils/dtos/user-signin';

import * as S from './styles';

function AdminSignIn(): ReactElement {
  const { signin } = useAuth();
  const router = useHistory();
  const formRef = useRef<FormHandles>(null);

  const signInButtonStyle = useRef<CSSProperties>({
    marginTop: '1.8rem',
    borderRadius: '8px',
    maxWidth: '100%',
  });

  const handleSignInAdmin = useCallback(
    async (data: UserSignInDto) => {
      try {
        formRef.current?.setErrors({});
        await userSignInSchema.validate(data, {
          abortEarly: false,
        });
        await signin(data);
        router.push('/admin/leads');
        toast.success('Usuário logado.');
      } catch (error) {
        handleYupValidationError(formRef, error);
      }
    },
    [router, signin],
  );

  return (
    <>
      <ToastContainer />
      <S.Container>
        <S.Content>
          <S.SignInClientComLogo
            src={LogoColored}
            alt="ClientCom"
            title="ClientCom"
          />
          <S.SignInForm ref={formRef} onSubmit={handleSignInAdmin}>
            <S.SignInFormTitle>Faça login na sua conta.</S.SignInFormTitle>
            <Input name="email" id="email" placeholder="E-mail" />
            <Input
              name="password"
              id="password"
              placeholder="Senha"
              showIconPassword
            />
            <Button containerStyle={signInButtonStyle.current} type="submit">
              Entrar
            </Button>
          </S.SignInForm>
        </S.Content>
      </S.Container>
    </>
  );
}

export default AdminSignIn;
