import { Form } from '@unform/web';
import styled from 'styled-components';

import { NotebookWomanBlackBg } from '~/assets/images';

export const Container = styled.main`
  width: 100%;
  min-height: 100vh;
  display: grid;
  background: url(${NotebookWomanBlackBg}) no-repeat;
  background-size: contain;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1176px) {
    grid-template-columns: 1fr;
    background: none;
  }
`;

export const Content = styled.section`
  display: flex;
  width: 100%;
  grid-column-start: 2;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-content: center;

  @media (max-width: 1176px) {
    width: 90%;
    grid-column-start: 1;
  }
`;

export const SignInClientComLogo = styled.img`
  display: none;

  @media (max-width: 1176px) {
    display: block;
    margin-bottom: 6.4rem;
  }
`;

export const SignInForm = styled(Form)`
  max-width: 382px;
  width: 100%;
`;

export const SignInFormTitle = styled.h1`
  font-weight: regular;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 3.2rem;
`;
