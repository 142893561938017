import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacings.large};

  @media (max-width: ${breakpoints.small}) {
    padding: 0 1.6rem;
  }
`;

export const Title = styled.h1`
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 800;
`;

export const TableWrapper = styled.section`
  width: 100%;
  padding: 3.2rem 2rem 6.4rem 2rem;
  border-radius: 7px;
  border: 1px solid rgba(70, 70, 70, 0.1);
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: ${breakpoints.small}) {
    padding: 4rem 1.6rem;
  }
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  column-gap: 6.4rem;
  align-items: center;
  padding: 0 2.4rem;
  margin-bottom: 4rem;

  @media (max-width: ${breakpoints.small}) {
    padding: 0 1.6rem;
    flex-direction: column;
    margin-bottom: 1.6rem;
  }
`;

export const TableWrapperButton = styled.button<{ isInactive?: boolean }>`
  background: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
  font-weight: 700;

  ${({ isInactive }) =>
    isInactive &&
    css`
      opacity: 0.5;
    `}

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 1.6rem;
  }
`;

export const TableButton = styled.button`
  background: none;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

export const TableSearchForm = styled(Form)``;

export const TableFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6.4rem;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    margin-top: 3.2rem;
  }
`;
