import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  lazy,
  Suspense,
} from 'react';

import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CSSProperties } from 'styled-components';
import {
  UserAvatar,
  Megaphone,
  Operadoras,
  Relatorios,
  Vendedor,
  ArrowDown,
  LogoColored,
  Bell,
} from '~/assets/icons';

import { Loading, MenuMobileToggle } from '~/components';

import * as S from './styles';

// lazy components
const AvatarDropdown = lazy(() => import('./AvatarDropdown'));

interface DashboardProps {
  children?: ReactNode;
}

interface SidebarNavOption {
  label: string;
  path: string;
  icon: string;
}

function Dashboard({ children }: DashboardProps): ReactElement {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [sidebarMobileStatus, setSidebarMobileStatus] = useState(false);
  const { pathname } = useLocation();

  const headerAvatarArrowStyle = useRef<CSSProperties>({
    width: 11,
    height: 7,
  });

  const handleToggleDropdown = useCallback(() => {
    setDropdownStatus(!dropdownStatus);
  }, [dropdownStatus]);

  const handleDisableDropdown = useCallback(() => {
    if (dropdownStatus) handleToggleDropdown();
  }, [dropdownStatus, handleToggleDropdown]);

  const handleDisableSidebarInMobile = useCallback(() => {
    if (sidebarMobileStatus) setSidebarMobileStatus(false);
  }, [sidebarMobileStatus]);

  useEffect(() => {
    setSidebarMobileStatus(false);
  }, [pathname]);

  const SideBarNavMemo = useMemo(() => {
    const sidebarNavOptions: SidebarNavOption[] = [
      { label: 'Leads (Solicitações)', path: '/admin/leads', icon: Megaphone },
      { label: 'Vendedores', path: '/admin/sellers', icon: Vendedor },
      { label: 'Operadoras', path: '/admin/operators', icon: Operadoras },
      { label: 'Relatórios', path: '/admin/reports', icon: Relatorios },
      { label: 'Push', path: '/admin/push', icon: Bell },
    ];

    return sidebarNavOptions.map(({ label, path, icon }) => (
      <S.SidebarNavItem key={path} title={label}>
        <S.SidebarNavLink to={path}>
          <S.SidebarNavLinkIcon src={icon} alt={label} />
          {label}
        </S.SidebarNavLink>
      </S.SidebarNavItem>
    ));
  }, []);

  return (
    <>
      <ToastContainer />
      <S.Container onClick={handleDisableDropdown}>
        <S.Sidebar isActive={sidebarMobileStatus}>
          <S.SidebarLogoLink to="/admin/leads">
            <S.SidebarLogo
              src={LogoColored}
              alt="ClientCom"
              title="ClientCom"
            />
          </S.SidebarLogoLink>
          <S.SidebarNav>
            <S.SidebarNavMenu>{SideBarNavMemo}</S.SidebarNavMenu>
          </S.SidebarNav>
        </S.Sidebar>
        <S.Content
          sidebarIsActive={sidebarMobileStatus}
          onClick={handleDisableSidebarInMobile}
        >
          <S.Header>
            <MenuMobileToggle
              menuVisible={sidebarMobileStatus}
              setMenuVisible={setSidebarMobileStatus}
            />
            <S.HeaderAvatar type="button" onClick={handleToggleDropdown}>
              <S.HeaderAvatarUserName>Administrador</S.HeaderAvatarUserName>
              <S.HeaderAvatarImg
                src={UserAvatar}
                alt="Imagem do perfil"
                title="Imagem do perfil"
              />
              <img
                src={ArrowDown}
                alt="Menu"
                style={headerAvatarArrowStyle.current}
              />
            </S.HeaderAvatar>
          </S.Header>
          <S.ContentSection>{children}</S.ContentSection>
        </S.Content>
      </S.Container>

      <Suspense fallback={<Loading loading />}>
        <AvatarDropdown dropdownIsActive={dropdownStatus} />
      </Suspense>
    </>
  );
}

export default Dashboard;
