import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  text-align: center;
  margin-top: 7rem;
`;

export const Wrapper = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    position: relative;
    z-index: 1;
    span {
      position: relative;
      z-index: 1;
    }
  }
`;

export const Counter = styled.p`
  position: fixed;
  z-index: 2;
  font-size: 2rem;
  font-weight: 700;
`;
