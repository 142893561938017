import { AxiosPromise } from 'axios';

import api from './api';

import { IRequestOperatorResponse } from '~/models/Common';
import { ILead, IRequestLeadData } from '~/models/Lead';

export default class LeadService {
  static getLeadById(leadId: string): AxiosPromise<ILead> {
    return api.get(`/leads/${leadId}`);
  }

  static requestLead(
    data: IRequestLeadData,
    token: string,
  ): AxiosPromise<ILead[]> {
    return api.post('/leads/request', data, {
      headers: {
        Authorization: token
          ? `Bearer ${token}`
          : api.defaults.headers.Authorization,
      },
    });
  }

  static suggestOperador(
    name: string,
    token: string,
  ): AxiosPromise<IRequestOperatorResponse> {
    return api.post(
      '/operators/suggest-operator',
      { name },
      {
        headers: {
          Authorization: token
            ? `Bearer ${token}`
            : api.defaults.headers.Authorization,
        },
      },
    );
  }

  static putRatingLead(
    userId: string,
    leadId: string,
    rating: number,
  ): AxiosPromise {
    return api.put(`/leads/rating-lead/${userId}/${leadId}/${rating}`);
  }
}
