import { AxiosPromise } from 'axios';

import { ICredentials, ISignupRequest, ISignupResponse } from '../models/Auth';

import api from './api';

export default class SessionService {
  static readonly baseRoute = '/auth';

  static signin(data: ICredentials): AxiosPromise {
    return api.post(`${this.baseRoute}/signin`, data);
  }

  static signupClient(data: ISignupRequest): AxiosPromise<ISignupResponse> {
    return api.post(`${this.baseRoute}/signup`, data);
  }
}
