import { AxiosPromise } from 'axios';

import api from './api';

export default class SellerService {
  static readonly baseRoute = '/seller';

  static putUpdateCredits(credits: number): AxiosPromise {
    return api.put(`${this.baseRoute}/update-credits`, { credits });
  }
}
