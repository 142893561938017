import { ReactElement, useCallback, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { getValidationErrors } from '~/utils/functions';

import * as S from './styles';
import { Button, Input } from '~/components';
import { AdminService } from '~/services';

export interface IMensageForm {
  title: string;
  description: string;
}
function Reports(): ReactElement {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: IMensageForm) => {
    try {
      setLoading(true);
      const schema = Yup.object().shape({
        title: Yup.string()
          .max(50, 'Titulo deve ter no máximo 50 caracteres')
          .required('Título é obrigatório'),
        description: Yup.string()
          .max(150, 'Descrição deve ter no máximo 150 caracteres')
          .required('Descrição é obrigatório'),
      });
      await schema.validate({ ...data }, { abortEarly: false });

      await AdminService.PushMensage(data);
      toast.success('Notificação realizada com sucesso!');
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        setLoading(false);
        Object.values(errors).forEach((value: string) => {
          toast.error(`${value}`);
        });
      }
    }
  }, []);

  return (
    <>
      <S.Container>
        <S.Title>Push</S.Title>
        <S.TableWrapper>
          <S.FormCard ref={formRef} onSubmit={handleSubmit}>
            <S.InputWrapper>
              <S.Label>Título</S.Label>
              <Input name="title" placeholder="Título" />
              <S.Label>Descrição</S.Label>
              <Input name="description" placeholder="Descrição" />
            </S.InputWrapper>

            <Button
              onClick={() => {
                formRef.current?.submitForm();
              }}
            >
              Enviar
            </Button>
          </S.FormCard>
        </S.TableWrapper>
      </S.Container>
    </>
  );
}

export default Reports;
