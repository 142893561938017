import styled from 'styled-components';

import { breakpoints } from '~/styles/tools/breakpoints';

const trHeight = '4.2rem';

export const TableWrapper = styled.section`
  width: 100%;

  @media (max-width: ${breakpoints.medium}) {
    overflow-x: scroll;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  height: ${trHeight};
  border-radius: 3px 3px 0 0;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  th {
    text-align: center;

    @media (max-width: ${breakpoints.medium}) {
      min-width: 100px;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export const TableBody = styled.tbody`
  > tr {
    height: ${trHeight};
    border-bottom: 1px solid #dcdcdc;

    &:hover {
      cursor: pointer;
      background-color: #ececec;
    }
  }

  td {
    text-align: center;

    @media (max-width: ${breakpoints.medium}) {
      min-width: 100px;
    }
  }
`;

export const TableNoItems = styled.h2`
  text-align: center;
  font-weight: 400;
  margin-top: 6.4rem;
  opacity: 0.5;
`;
