import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #2d2d2d;

  img {
    width: 23.2rem;
    height: auto;
    margin-right: 20rem;
  }

  @media (max-width: 800px) {
    justify-content: center;
    height: 12rem;

    img {
      width: 15rem;
      margin-right: 0;
    }
  }
`;
