import React from 'react';

import { AuthProvider } from './auth';
import { AppLocaleProvider } from './locale';
import { AppThemeProvider } from './theme';
import { LoadingProvider } from './loading';

export const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <LoadingProvider>
      <AppLocaleProvider>
        <AppThemeProvider>{children}</AppThemeProvider>
      </AppLocaleProvider>
    </LoadingProvider>
  </AuthProvider>
);
