import styled from 'styled-components';

export const BlackBg = styled.div`
  width: 100%;
  max-height: 50rem;
  background-color: #2d2d2d;
  display: flex;
  justify-content: space-around;
  margin-top: 10rem;
  padding: 0 20px;
  position: relative;

  @media (max-width: 930px) {
    flex-direction: column;
    max-height: none;
    align-items: center;
  }
`;

export const BlackBgImage = styled.div`
  width: 70rem;
  position: absolute;
  z-index: 1;
  height: 50rem;
  overflow: hidden;
`;

export const WomanDiv = styled.div`
  position: relative;
  min-height: 65rem;
  display: flex;
  z-index: 2;

  > img {
    position: relative;
    width: 100%;
    max-width: 60rem;
    height: auto;
    top: -5.5rem;
    /* align-self: flex-start; */
  }

  @media (max-width: 930px) {
    min-height: 0;
    width: 40rem;
    top: 1rem;
  }

  @media (max-width: 470px) {
    width: 30rem;
    top: 2.6rem;
  }

  @media (max-width: 345px) {
    width: 25rem;
    top: 3rem;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media (max-width: 1040px) {
    max-width: 40rem;
  }
  @media (max-width: 930px) {
    max-width: 50rem;
  }
`;

export const Title = styled.h1`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: #fff;
  margin-top: 4rem;
  z-index: 2;
  text-align: right;

  strong {
    color: #ffb70f;
  }

  @media (max-width: 930px) {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    text-align: left;
    font-size: 3rem;
  }
  @media (max-width: 330px) {
    font-size: 2.5rem;
    strong {
      font-size: 2.4rem;
    }
  }
`;

export const Button = styled.button`
  background-color: #ffb70f;
  margin-top: 30px;
  margin-bottom: 70px;
  padding: 10px 15px;
  border-radius: 40px;
  width: 190px;
  align-self: flex-end;

  font-family: Calibri, sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #2d2d2d;

  @media (max-width: 768px) {
    align-self: auto;
  }
`;
