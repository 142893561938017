import styled, { css } from 'styled-components';

interface PointProps {
  isFilled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
`;

export const ProgressPoint = styled.div<PointProps>`
  width: 40px;
  height: 8px;
  border-radius: 10px;
  margin-right: 8px;

  background: #f3f3f3 0% 0% no-repeat padding-box;

  ${(props) =>
    props.isFilled &&
    css`
      background: transparent
        linear-gradient(
          90deg,
          ${({ theme }) => theme.colors.primary} 0%,
          ${({ theme }) => theme.colors.primary} 100%
        )
        0% 0% no-repeat padding-box;
    `}
`;
