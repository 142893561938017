import { InputHTMLAttributes, ReactElement } from 'react';

import { FiSearch } from 'react-icons/fi';

import * as theme from '~/styles/themes';

import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

function InputAdmin({ name, ...rest }: InputProps): ReactElement {
  return (
    <S.Container>
      <input type="text" name={name} {...rest} placeholder="Busque por nome" />
      <FiSearch
        style={{
          color: theme.light.colors.primary ?? theme.dark.colors.primary,
        }}
        size={22}
      />
    </S.Container>
  );
}

export default InputAdmin;
