import styled from 'styled-components';

import { Form } from '@unform/web';
import { breakpoints } from '~/styles/tools/breakpoints';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacings.large};

  > textarea {
    flex: 1;
    width: 100%;
    background: #f9f9f9;
    border: 0;
    border-radius: 0.8rem;
    padding: 5px 20px;

    color: #394761;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.52px;

    ::placeholder {
      color: #39476180;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: 0.52px;
    }
  }

  @media (max-width: ${breakpoints.medium}) {
    padding: 0 1.6rem;
  }
`;

export const Title = styled.h1`
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 800;
`;
export const FormCard = styled(Form)`
  width: 700px;
  padding: 4rem;
  background-color: #fff;
  border-radius: 1.5rem;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 15px 17px #468ee526;
`;
export const InputWrapper = styled.div`
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Label = styled.h2`
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.52px;
  color: #394761;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;
export const TableWrapper = styled.section`
  width: 100%;
  padding: 3.2rem 2rem 6.4rem 2rem;
  border-radius: 7px;
  border: 1px solid rgba(70, 70, 70, 0.1);
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: ${breakpoints.medium}) {
    padding: 4rem 1.6rem;
  }
`;
