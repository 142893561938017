import React from 'react';

import { LogoWhite } from '~/assets/icons';

import * as S from './styles';

const Footer: React.FC = () => (
  <S.Container>
    <img src={LogoWhite} alt="Logo ClientCom" />
  </S.Container>
);

export default Footer;
